const videos = [
  {
    id: 1,
    title: 'Federal Tax Engine: Filing Status',
    link: 'calculators/federal-tax-engine/filing-status.mp4',
    code: 'syLt8r'
  },
  {
    id: 2,
    title: 'Federal Tax Engine: Adjusted Gross Income (AGI)',
    link: 'calculators/federal-tax-engine/agi.mp4',
    code: 'nNkevq'
  },
  {
    id: 3,
    title: 'Federal Tax Engine: Federal Taxable Income',
    link: 'calculators/federal-tax-engine/federal-taxable-income.mp4',
    code: '0U47KY'
  },
  {
    id: 4,
    title: 'Federal Tax Engine: Medicare Wages and Tips',
    link: 'calculators/federal-tax-engine/medicare-wages-and-tips.mp4',
    code: 'QKOfX3'
  },
  {
    id: 5,
    title: 'Federal Tax Engine: Line 3 of Schedule SE',
    link: 'calculators/federal-tax-engine/line-three-schedule-se.mp4',
    code: 'vDDMhy'
  },
  {
    id: 6,
    title: 'Federal Tax Engine: Long Term Capital Gains (LTCG)',
    link: 'calculators/federal-tax-engine/long-term-capital-gains.mp4',
    code: 'juQF1n'
  },
  {
    id: 7,
    title: 'Federal Tax Engine: Qualified Dividends (QD)',
    link: 'calculators/federal-tax-engine/qualified-dividends.mp4',
    code: 'jwFX0I'
  },
  {
    id: 8,
    title: 'Federal Tax Engine: Investment Income Portion of AGI',
    link: 'calculators/federal-tax-engine/investment-agi.mp4',
    code: '1klsuL'
  },
  {
    id: 9,
    title: 'Teaching Tax Flow: Income Types',
    link: 'shared/income-types.mp4',
    code: '1nnKq'
  },
  {
    id: 10,
    title: 'Federal Estimated Tax Applied From Prior Year',
    link: 'calculators/federal-income-tax-balance-due-overpayment/federal-estimated-tax-applied-from-prior-year.mp4',
    code: 'WsGXi'
  },
  {
    id: 11,
    title: 'Federal Estimated Tax Payments',
    link: 'calculators/federal-income-tax-balance-due-overpayment/federal-estimated-tax-payment.mp4',
    code: 'tvgkb'
  },
  {
    id: 12,
    title: 'Federal Tax Withholding',
    link: 'calculators/federal-income-tax-balance-due-overpayment/tax-withholding.mp4',
    code: 'qOyLk'
  }
]

const getVideoById = (requestedId: string) => {
  return videos.find(({ id }) => id === parseFloat(requestedId))!
}

const getVideoByCode = (requestedCode: string) => {
  return videos.find(({ code }) => code === requestedCode)!
}

export {
  getVideoById,
  getVideoByCode
}
