import { CURRENT_YEAR } from './default-values'

export interface TSelfEmploymentRates {
  maxFica: number
  socialSecurityMultiplier: number
  socialSecurityRate: number
  medicareRate: number
  year: number
}

const years: TSelfEmploymentRates[] = [
  {
    year: 2021,
    maxFica: 142800,
    socialSecurityMultiplier: 0.9235,
    socialSecurityRate: 0.124,
    medicareRate: 0.029
  },
  {
    year: 2022,
    maxFica: 147000,
    socialSecurityMultiplier: 0.9235,
    socialSecurityRate: 0.124,
    medicareRate: 0.029
  }
]

const getSelfEmploymentTaxes = (
  filingYear: number = CURRENT_YEAR,
  selfEmploymentIncome: number = 0,
  medicareWagesAndTips: number = 0
) => {
  const {
    socialSecurityMultiplier,
    maxFica,
    socialSecurityRate,
    medicareRate
  } = years.find((y) => y.year === filingYear)!
  const multiplierIncome = selfEmploymentIncome * socialSecurityMultiplier
  const maxFicaLessWages = maxFica - medicareWagesAndTips
  const ficaTax = Math.max(
    0,
    Math.min(multiplierIncome, maxFicaLessWages) * socialSecurityRate
  )
  const medicareTax = multiplierIncome * medicareRate
  return {
    ficaTax,
    ficaTaxExplanation: `12.4% multiplied by the lesser of the following: 1.) Self-employment income of $${selfEmploymentIncome.toLocaleString()} multiplied by the ${filingYear} Social Security Medicare multiplier ${
      socialSecurityMultiplier * 100 + '%'
    } and 2.) FICA wage ceiling for ${filingYear} - $${maxFica.toLocaleString()} - less wages of $${medicareWagesAndTips.toLocaleString()}`,
    medicareTax,
    medicareTaxExplanation: `Self-employment income of $${selfEmploymentIncome.toLocaleString()} multiplied by the Social Security Medicare multiplier ${
      socialSecurityMultiplier * 100 + '%'
    } multiplied by the Medicare tax rate of ${
      (medicareRate * 100).toLocaleString() + '%'
    }`
  }
}

const getFicaCeilingHit = (
  medicareWagesAndTips: number,
  selfEmploymentIncome: number,
  filingYear: number
) => {
  return (
    medicareWagesAndTips + selfEmploymentIncome >
    (years.find((y) => y.year === filingYear)?.maxFica || 0)
  )
}

const getYearRates = (year: number) => {
  const index =
    years.findIndex((bracketYear) => bracketYear.year === year)
  return index > -1
    ? years[index]
    : undefined
}

export { getSelfEmploymentTaxes, getFicaCeilingHit, getYearRates }
