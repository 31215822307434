import { Form, InputNumber, Select, Switch, SwitchProps, Tooltip } from 'antd'
import { useWatch } from 'antd/lib/form/Form'
import { currency } from '../../utils/currency'
import CalculatorNotes from '../shared/notes'
import CalculatorResults from '../shared/results'
import CalculatorShell from '../shared/shell'
import { dollarFormatter, parser } from '../../constants/input-number-formats'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { CURRENT_YEAR } from '../../constants/default-values'
import FederalTaxEngine from '../../types/federal-tax-engine'
import getSavedFederalTaxEngine from '../../utils/get-saved-federal-tax-engine'
import FederalTaxEngineEditor from '../shared/federal-tax-engine-editor'
import FteComparison from '../shared/fte-comparison'
import {
  TOffsetIncomeType,
  getOffsetIncomeTypes,
  applyOffsettingIncome
} from '../shared/utils/offset-income-types'
import {
  incomeReductionType,
  IncomeReductionType
} from '../../types/income-reduction'
import VideoLabel from '../../components/video-label/VideoLabel'
const { Option } = Select

const CostSegregationRealEstate = () => {
  const [reducingIncomeTypeForm] = Form.useForm()
  const reducingIncomeType = useWatch(
    'reducingIncomeType',
    reducingIncomeTypeForm
  )

  return (
    <CalculatorShell title='Cost Segregation for Real Estate' usesFte>
      <Form
        form={reducingIncomeTypeForm}
        layout='vertical'
        initialValues={{
          reducingIncomeType: null
        }}
      >
        <Form.Item
          label={
            <VideoLabel
              label='What type of income will the qualifying asset be reducing?'
              videoId='9'
            />
          }
          name='reducingIncomeType'
        >
          <Select placeholder='Please select a type of income'>
            {incomeReductionType.map((type) => (
              <Option key={type} value={type}>{type}</Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      {reducingIncomeType
        ? (
            reducingIncomeType === 'C-Corporation'
              ? (
                <CCorporationForm />
                )
              : (
                <NonCCorporationForm
                  reducingIncomeType={reducingIncomeType as IncomeReductionType}
                />
                )
          )
        : null}
    </CalculatorShell>
  )
}

export default CostSegregationRealEstate

const CCorporationForm = () => {
  const [form] = Form.useForm()
  const buildingPortion = useWatch<number>('buildingPortion', form)
  const [autoCalculate, setAutoCalculate] = useState(false)
  const currentDeduction = useWatch<number>('currentDeduction', form)
  const autoCurrentDeduction = useWatch<number>('autoCurrentDeduction', form)
  const estimatedCosts = useWatch<number>('estimatedCosts', form)
  const propertyType = useWatch('propertyType', form)
  const deduction = autoCalculate ? autoCurrentDeduction : currentDeduction
  const depreciationDeductionAbove =
    deduction -
    (buildingPortion - deduction) / (propertyType === 'Commercial' ? 79 : 55)

  useEffect(() => {
    if (autoCalculate) {
      form.setFieldsValue({
        autoCurrentDeduction: (0.225 * buildingPortion || 0).toFixed(2)
      })
    }
  }, [buildingPortion, autoCalculate])

  return (
    <>
      <Form
        form={form}
        layout='vertical'
        initialValues={{
          buildingPortion: 0,
          currentDeduction: 0,
          autoCurrentDeduction: 0,
          estimatedCosts: 0,
          propertyType: 'Residential'
        }}
      >
        <Form.Item
          label='Cost basis of building portion of asset placed into service:'
          name='buildingPortion'
        >
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item
          label={
            <AutoCalculateToggle
              text='Enter current year deduction due to costs segregation study'
              onChange={setAutoCalculate}
              checked={autoCalculate}
            />
          }
          name={autoCalculate ? 'autoCurrentDeduction' : 'currentDeduction'}
        >
          <InputNumber
            disabled={autoCalculate}
            max={buildingPortion}
            formatter={dollarFormatter}
            parser={parser}
          />
        </Form.Item>
        <Form.Item
          label='Estimated costs of cost segregation study:'
          name='estimatedCosts'
        >
          <InputNumber formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item label='Type of property:' name='propertyType'>
          <Select placeholder='Please select property type'>
            {['Commercial', 'Residential'].map((type) => (
              <Option key={type} value={type}>{type}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label='Current year depreciation deduction above straight-line depreciation for 12 months:'>
          <InputNumber
            disabled
            value={depreciationDeductionAbove}
            formatter={dollarFormatter}
            parser={parser}
          />
        </Form.Item>
      </Form>
      <CalculatorResults
        results={[
          {
            title: 'Current Year Cash Flow',
            currencyResult: -1 * estimatedCosts || 0
          },
          {
            title: 'Current Year Tax Flow',
            currencyResult: 0.21 * depreciationDeductionAbove || 0
          },
          {
            title: 'Expected Current Year Net Tax Flow',
            currencyResult:
              -1 * estimatedCosts + 0.21 * depreciationDeductionAbove || 0,
            boldTitle: true
          }
        ]}
      >
        <CalculatorNotes
          notes={[
            'Calculations assume entire depreciation deduction allowed and asset placed into service in current year.',
            'Calculations assumes building portion of asset would be depreciated on a straight-line basis.',
            ' Does not factor in future depreciation recapture.'
          ]}
        />
      </CalculatorResults>
    </>
  )
}

const NonCCorporationForm: React.FC<{
  reducingIncomeType: IncomeReductionType
}> = ({ reducingIncomeType }) => {
  const [form] = Form.useForm()
  const filingYear = useWatch('filingYear', form)
  const buildingPortion = useWatch('buildingPortion', form)
  const [autoCalculate, setAutoCalculate] = useState(false)
  const autoCurrentDeduction = autoCalculate
    ? Math.round(0.225 * buildingPortion)
    : 0
  const currentDeduction = useWatch<number>('currentDeduction', form)
  const estimatedCosts = useWatch<number>('estimatedCosts', form)
  const offsetIncomeType = useWatch('offsetIncomeType', form)
  const [modalVisible, setModalVisible] = useState(false)
  const [preTax, setPreTax] = useState(0)
  const [postTax, setPostTax] = useState(0)
  const [incomeTypes, setIncomeTypes] = useState<TOffsetIncomeType[]>([])
  const [postEngine, setPostEngine] = useState<FederalTaxEngine>(
    new FederalTaxEngine()
  )
  const propertyType = useWatch('propertyType', form)
  const [depreciationDeductionAbove, setDepreciationDeductionAbove] =
    useState(0)

  useEffect(() => {
    const deduction = autoCalculate ? autoCurrentDeduction : currentDeduction
    setDepreciationDeductionAbove(
      deduction -
        (buildingPortion - deduction) /
          (propertyType === 'Commercial' ? 79 : 55)
    )
  }, [
    autoCalculate,
    currentDeduction,
    propertyType,
    buildingPortion,
    autoCurrentDeduction
  ])

  useEffect(() => {
    form.setFieldsValue({ offsetIncomeType: null })
  }, [reducingIncomeType])

  useEffect(() => {
    if (!modalVisible) {
      const incomeTypes = getOffsetIncomeTypes(filingYear).filter((type) => {
        if (reducingIncomeType === 'Self-employment Income') {
          return [
            'selfEmploymentIncome',
            'spouseSelfEmploymentIncome'
          ].includes(type.name)
            ? type
            : null
        } else {
          return type.name === 'investmentMagi' ? type : null
        }
      })

      if (incomeTypes.length === 1) {
        form.setFieldsValue({ offsetIncomeType: incomeTypes[0].name })
      }

      setIncomeTypes(incomeTypes)
    }
  }, [filingYear, modalVisible, offsetIncomeType, reducingIncomeType])

  useEffect(() => {
    let engine = getSavedFederalTaxEngine(filingYear)
    setPreTax(engine.results.estimatedTotalTaxes)
    if (offsetIncomeType) {
      engine = applyOffsettingIncome(
        engine,
        offsetIncomeType,
        depreciationDeductionAbove,
        true
      )
      engine.calculate()
      setPostTax(engine.results.estimatedTotalTaxes)
      setPostEngine(engine)
    }
  }, [depreciationDeductionAbove, modalVisible, filingYear, offsetIncomeType])

  return (
    <>
      <Form
        form={form}
        layout='vertical'
        initialValues={{
          filingYear: CURRENT_YEAR,
          buildingPortion: 0,
          offsetIncomeType: null,
          currentDeduction: 0,
          propertyType: 'Residential'
        }}
      >
        <Form.Item label='Filing year:' name='filingYear'>
          <Select placeholder='Select a filing year'>
            {[2021, 2022].map((year, index) => (
              <Option key={index} value={year}>
                {year}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <FederalTaxEngineEditor
            type='link'
            filingYear={filingYear}
            onModalViewChange={(visible) => setModalVisible(visible)}
          />
        </Form.Item>
        <Form.Item label={`${filingYear} base federal income tax:`}>
          <InputNumber
            disabled
            value={preTax}
            formatter={dollarFormatter}
            parser={parser}
          />
        </Form.Item>
        <Form.Item
          label='Cost basis of building portion of asset placed into service:'
          name='buildingPortion'
        >
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item
          hidden={!autoCalculate}
          label={
            <AutoCalculateToggle
              text={`Enter ${filingYear} deduction due to costs segregation study`}
              onChange={setAutoCalculate}
              checked={autoCalculate}
            />
          }
        >
          <InputNumber
            disabled
            value={autoCurrentDeduction}
            formatter={dollarFormatter}
            parser={parser}
          />
        </Form.Item>
        <Form.Item
          hidden={autoCalculate}
          label={
            <AutoCalculateToggle
              text={`Enter ${filingYear} deduction due to costs segregation study`}
              onChange={setAutoCalculate}
              checked={autoCalculate}
            />
          }
          name='currentDeduction'
        >
          <InputNumber
            max={buildingPortion}
            formatter={dollarFormatter}
            parser={parser}
          />
        </Form.Item>
        <Form.Item
          label='Estimated costs of cost segregation study:'
          name='estimatedCosts'
        >
          <InputNumber formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item label='Type of property:' name='propertyType'>
          <Select placeholder='Please select property type'>
            {['Commercial', 'Residential'].map((type) => (
              <Option key={type} value={type}>{type}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='What type of income will the qualifying asset be reducing?'
          name='offsetIncomeType'
        >
          <Select
            placeholder='Select an income type'
            disabled={incomeTypes.length === 1}
          >
            {incomeTypes.map((type, index) => (
              <Option key={index} value={type.name}>
                {type.description}: {currency.format(type.amount)}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={`${filingYear} depreciation deduction above straight-line depreciation for 6 months:`}
        >
          <InputNumber
            disabled
            value={depreciationDeductionAbove}
            formatter={dollarFormatter}
            parser={parser}
          />
        </Form.Item>
        <Form.Item
          label={`${filingYear} post cost segregation study federal tax:`}
        >
          <InputNumber
            disabled
            value={postTax}
            formatter={dollarFormatter}
            parser={parser}
          />
        </Form.Item>
      </Form>
      <CalculatorResults
        results={[
          {
            title: 'Current Year Cash Flow',
            currencyResult: -1 * estimatedCosts || 0
          },
          {
            title: 'Current Year Tax Flow',
            currencyResult: (preTax || 0) - (postTax || 0)
          },
          {
            title: 'Expected Current Year Net Tax Flow',
            currencyResult:
              (preTax || 0) - (postTax || 0) + (-1 * estimatedCosts || 0),
            boldTitle: true
          }
        ]}
      >
        <CalculatorNotes
          notes={[
            'Calculations assume entire depreciation deduction allowed and asset placed into service in current year.',
            'Calculations assumes building portion of asset would be depreciated on a straight-line basis.',
            'Does not factor in future depreciation recapture.'
          ]}
        />
        <FteComparison filingYear={filingYear} results={postEngine} />
      </CalculatorResults>
    </>
  )
}

const AutoCalculateToggle: React.FC<SwitchProps & { text: string }> = ({
  text,
  checked,
  onChange
}) => {
  return (
    <span style={{ marginRight: 3, display: 'block' }}>
      {text} or auto-calculate &nbsp;
      <Switch
        checked={checked}
        style={{ marginRight: 6, marginBottom: 3 }}
        onChange={onChange}
        size='small'
      />
      <Tooltip
        trigger={['hover', 'click']}
        title='Calculates at 22.5% of building portion cost basis'
        overlayInnerStyle={{ textAlign: 'center' }}
      >
        <QuestionCircleOutlined />
      </Tooltip>
    </span>
  )
}
