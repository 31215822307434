import { statuses } from '../../../constants/filing-status'
import {
  getCapitalGainsAndDividendsBracket,
  TBracketThreshold
} from '../../../constants/ltcg-qd-brackets'

const LTCGAndQDBrackets: React.FC<{ selectedYear: number }> = ({
  selectedYear
}) => {
  const bracketYearTiers = statuses.map((status) => ({
    status,
    data: getCapitalGainsAndDividendsBracket(
      status.code,
      selectedYear
    ) as TBracketThreshold[]
  }))

  if (bracketYearTiers.every((t) => t.data.length === 0)) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <h3>No data found for {selectedYear}</h3>
      </div>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        gap: 24,
        justifyContent: 'center'
      }}
    >
      {bracketYearTiers.map(({ status, data }) => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <span>
              <table border={1}>
                <thead style={{ backgroundColor: '#d3d3d3' }}>
                  <tr>
                    <th colSpan={2} style={{ padding: 6 }}>
                      {status.label}
                    </th>
                  </tr>
                  <tr>
                    <th style={{ padding: 6 }}>Tax Rate</th>
                    <th style={{ padding: 6 }}>Taxable Income</th>
                  </tr>
                </thead>
                {data.map((t, index) => (
                  <tr>
                    <td
                      style={{
                        textAlign: 'right',
                        paddingLeft: 10,
                        paddingRight: 10
                      }}
                    >
                      {t.percentage}%
                    </td>
                    <td
                      style={{
                        textAlign: 'right',
                        paddingLeft: 10,
                        paddingRight: 10
                      }}
                    >
                      {index === 0
                        ? `up to $${t.maxTaxableIncome.toLocaleString()}`
                        : null}
                      {index > 0 && index !== data.length - 1
                        ? `$${t.minTaxableIncome.toLocaleString()} to $${t.maxTaxableIncome.toLocaleString()}`
                        : null}
                      {index === data.length - 1
                        ? `$${t.minTaxableIncome.toLocaleString()} or more`
                        : null}
                    </td>
                  </tr>
                ))}
              </table>
            </span>
          </div>
        )
      })}
    </div>
  )
}

export default LTCGAndQDBrackets
