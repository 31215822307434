import { Form, InputNumber, Select } from 'antd'
import { useWatch } from 'antd/lib/form/Form'
import CalculatorShell from '../shared/shell'
import { dollarFormatter, parser } from '../../constants/input-number-formats'
import { currency } from '../../utils/currency'
import CalculatorResults from '../shared/results'
import CalculatorNotes from '../shared/notes'
import FederalTaxEngineEditor from '../shared/federal-tax-engine-editor'
import { useEffect, useState } from 'react'
import { CURRENT_YEAR } from '../../constants/default-values'
import getSavedFederalTaxEngine from '../../utils/get-saved-federal-tax-engine'
import {
  applyOffsettingIncome,
  getOffsetIncomeTypes,
  TOffsetIncomeType
} from '../shared/utils/offset-income-types'
import FederalTaxEngine from '../../types/federal-tax-engine'
import FteComparison from '../shared/fte-comparison'
import {
  incomeReductionType,
  IncomeReductionType
} from '../../types/income-reduction'
import VideoLabel from '../../components/video-label/VideoLabel'
const { Option } = Select

const DeMinimusSafeHarborElection = () => {
  const [reducingIncomeTypeForm] = Form.useForm()
  const reducingIncomeType = useWatch(
    'reducingIncomeType',
    reducingIncomeTypeForm
  )

  return (
    <CalculatorShell title='De Minimus Safe Harbor Election' usesFte>
      <Form
        form={reducingIncomeTypeForm}
        layout='vertical'
        initialValues={{
          reducingIncomeType: null
        }}
      >
        <Form.Item
          label={
            <VideoLabel
              label='What type of income will the qualifying asset be reducing?'
              videoId='9'
            />
          }
          name='reducingIncomeType'
        >
          <Select placeholder='Please select a type of income'>
            {incomeReductionType.map((type) => (
              <Option key={type} value={type}>{type}</Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      {reducingIncomeType
        ? (
            reducingIncomeType === 'C-Corporation'
              ? (
                <CCorporationForm />
                )
              : (
                <NonCCorporationForm
                  reducingIncomeType={reducingIncomeType as IncomeReductionType}
                />
                )
          )
        : null}
    </CalculatorShell>
  )
}

export default DeMinimusSafeHarborElection

const CCorporationForm = () => {
  const [form] = Form.useForm()
  const qualifyingRepairs = useWatch('qualifyingRepairs', form)
  const deductionAboveDepreciation = qualifyingRepairs
    ? qualifyingRepairs - qualifyingRepairs / 55
    : 0

  return (
    <>
      <Form
        form={form}
        layout='vertical'
        initialValues={{
          qualifyingRepairs: 0,
          deductionAboveDepreciation: 0
        }}
      >
        <Form.Item
          label='Amount of qualifying repairs that are eligible for De Minimis Safe Harbor in current year:'
          name='qualifyingRepairs'
        >
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item label='Current year deduction above 27.5 year straight-line depreciation for 6 months:'>
          <InputNumber
            disabled
            value={deductionAboveDepreciation}
            formatter={dollarFormatter}
            parser={parser}
          />
        </Form.Item>
      </Form>
      <CalculatorResults
        results={[
          { title: 'Current Year Cash Flow', content: currency.format(0) },
          {
            title: 'Current Year Tax Flow',
            boldTitle: true,
            content: currency.format(deductionAboveDepreciation * 0.21)
          }
        ]}
      >
        <CalculatorNotes
          notes={[
            'Calculations assume entire safe harbor expense deduction allowed in current year.',
            'Calculations do not factor in changes to QBI. ',
            'Calculations assume qualifying repairs would be a 27.5 year asset if not deducted.'
          ]}
        />
      </CalculatorResults>
    </>
  )
}

const NonCCorporationForm: React.FC<{
  reducingIncomeType: IncomeReductionType
}> = ({ reducingIncomeType }) => {
  const [form] = Form.useForm()
  const filingYear = useWatch('filingYear', form)
  const qualifyingRepairs = useWatch('qualifyingRepairs', form)
  const offsetIncomeType = useWatch('offsetIncomeType', form)
  const deductionAboveDepreciation = qualifyingRepairs
    ? qualifyingRepairs - qualifyingRepairs / 55
    : 0
  const [modalVisible, setModalVisible] = useState(false)
  const [preTax, setPreTax] = useState(0)
  const [postTax, setPostTax] = useState(0)
  const [incomeTypes, setIncomeTypes] = useState<TOffsetIncomeType[]>([])
  const [postEngine, setPostEngine] = useState<FederalTaxEngine>(
    new FederalTaxEngine()
  )

  useEffect(() => {
    form.setFieldsValue({ offsetIncomeType: null })
  }, [reducingIncomeType])

  useEffect(() => {
    if (!modalVisible) {
      const incomeTypes = getOffsetIncomeTypes(filingYear).filter((type) => {
        if (reducingIncomeType === 'Self-employment Income') {
          return [
            'selfEmploymentIncome',
            'spouseSelfEmploymentIncome'
          ].includes(type.name)
            ? type
            : null
        } else {
          return type.name === 'investmentMagi' ? type : null
        }
      })

      if (incomeTypes.length === 1) {
        form.setFieldsValue({ offsetIncomeType: incomeTypes[0].name })
      }

      setIncomeTypes(incomeTypes)
    }
  }, [filingYear, modalVisible, offsetIncomeType, reducingIncomeType])

  useEffect(() => {
    let engine = getSavedFederalTaxEngine(filingYear)
    setPreTax(engine.results.estimatedTotalTaxes)

    if (offsetIncomeType) {
      engine = applyOffsettingIncome(
        engine,
        offsetIncomeType,
        deductionAboveDepreciation,
        true
      )
      engine.calculate()
      setPostTax(engine.results.estimatedTotalTaxes)
      setPostEngine(engine)
    }
  }, [qualifyingRepairs, modalVisible])

  return (
    <>
      <Form
        form={form}
        layout='vertical'
        initialValues={{
          filingYear: CURRENT_YEAR,
          qualifyingRepairs: 0,
          offsetIncomeType: null
        }}
      >
        <Form.Item label='Filing year:' name='filingYear'>
          <Select placeholder='Select a filing year'>
            {[2021, 2022].map((year, index) => (
              <Option key={index} value={year}>
                {year}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <FederalTaxEngineEditor
            type='link'
            filingYear={filingYear}
            onModalViewChange={(visible) => setModalVisible(visible)}
          />
        </Form.Item>
        <Form.Item
          label={`${filingYear} pre-De Minimus Safe Harbor Election federal tax:`}
        >
          <InputNumber
            disabled
            value={preTax}
            formatter={dollarFormatter}
            parser={parser}
          />
        </Form.Item>
        <Form.Item
          label='What type of income will the qualifying asset be reducing?'
          name='offsetIncomeType'
        >
          <Select
            placeholder='Select an income type'
            disabled={incomeTypes.length === 1}
          >
            {incomeTypes.map((type, index) => (
              <Option key={index} value={type.name}>
                {type.description}: {currency.format(type.amount)}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={`Amount of qualifying repairs that are eligible for De Minimis Safe Harbor in ${filingYear}:`}
          name='qualifyingRepairs'
        >
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item
          label={`${filingYear} deduction above 27.5 year straight-line depreciation for 6 months:`}
        >
          <InputNumber
            disabled
            value={deductionAboveDepreciation}
            formatter={dollarFormatter}
            parser={parser}
          />
        </Form.Item>
        <Form.Item
          label={`${filingYear} post-De Minimus Safe Harbor Election federal tax:`}
        >
          <InputNumber
            disabled
            value={postTax}
            formatter={dollarFormatter}
            parser={parser}
          />
        </Form.Item>
      </Form>
      <CalculatorResults
        results={[
          { title: 'Current Year Cash Flow', currencyResult: 0 },
          {
            title: 'Current Year Tax Flow',
            boldTitle: true,
            currencyResult: preTax - postTax
          }
        ]}
      >
        <CalculatorNotes
          notes={[
            'Calculations assume entire safe harbor expense deduction allowed in current year.',
            'Calculations do not factor in changes to QBI. ',
            'Calculations assume qualifying repairs would be a 27.5 year asset if not deducted.'
          ]}
        />
        <FteComparison filingYear={filingYear} results={postEngine} />
      </CalculatorResults>
    </>
  )
}
