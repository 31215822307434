import { Collapse, Space } from 'antd'
import { calculators, CalculatorStatusColors } from '../../calculator-metadata'
import CopyButton from '../../components/copy-button'
import styled from 'styled-components'
import CollapsePanel from 'antd/lib/collapse/CollapsePanel'

const Pill = styled.span`
  min-width: 50px;
  display: block;
  font-size: 10px;
  color: white;
  padding: 1px 6px;
  border-radius: 16px;
  margin-left: 6px;
  user-select: none;
  box-shadow: rgb(38, 57, 77) 0px 2px 4px 0px;
  text-align: center;
`

const CalculatorLink = styled.a`
  display: block;
  user-select: none;
  margin-right: 8px;

  &:hover {
    font-weight: bold;
  }
`

const Calculators = () => {
  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      {calculators.map((calculator, index) => (
        <Collapse key={calculator.name}>
          <CollapsePanel
            header={
              <div>
                <CalculatorLink
                  href={window.origin + '/' + calculator.path}
                  target=''
                  rel='noreferrer'
                >
                  {calculator.name}
                </CalculatorLink>
              </div>
            }
            key={index}
            extra={
              <Pill
                style={{
                  backgroundColor: CalculatorStatusColors.get(
                    calculator.status
                  )
                }}
              >
                {calculator.status}
              </Pill>
            }
          >
            <div style={{ display: 'flex' }}>
              {process.env.REACT_APP_ENV === 'production'
                ? (
                  <CopyButton
                    content={window.origin + '/' + calculator.path}
                    message={`"${calculator.name}"`}
                  />
                  )
                : <span>Details under construction</span>}
            </div>
          </CollapsePanel>
        </Collapse>
      ))}
    </Space>
  )
}

export default Calculators
