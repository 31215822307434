import { Collapse, Select, Space } from 'antd'
import CollapsePanel from 'antd/lib/collapse/CollapsePanel'
import { useState } from 'react'
import { CURRENT_YEAR } from '../../constants/default-values'
import LTCGAndQDBrackets from './ltcg-qd-brackets'
import MedicareTaxTriggers from './medicare-tax-triggers'
import NetInvestmentIncomeTaxTriggers from './net-investment-income-tax-triggers'
import RothContributionLimitsData from './roth-contribution-limits'
import SelfEmploymentRates from './self-employment-rates'
import TaxBracketsData from './tax-brackets'

const Data = () => {
  const [selectedYear, setSelectedYear] = useState(CURRENT_YEAR)

  const categories = [
    {
      name: 'Tax Brackets',
      component: <TaxBracketsData selectedYear={selectedYear} />
    },
    {
      name: 'Roth IRA Limits',
      component: <RothContributionLimitsData selectedYear={selectedYear} />
    },
    {
      name: 'Net Investment Income Tax Triggers',
      component: <NetInvestmentIncomeTaxTriggers selectedYear={selectedYear} />
    },
    {
      name: 'Self Employment Rates',
      component: <SelfEmploymentRates selectedYear={selectedYear} />
    },
    {
      name: 'Add’l. Medicare Tax Triggers',
      component: <MedicareTaxTriggers selectedYear={selectedYear} />
    },
    {
      name: 'LTCG/QD Brackets',
      component: <LTCGAndQDBrackets selectedYear={selectedYear} />
    }
  ]

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <div>
        <span>Select a year: </span>
        <Select
          size='small'
          defaultValue={selectedYear}
          onChange={setSelectedYear}
        >
          {[2021, 2022, 2023].map((year) => (
            <Select.Option key={year} value={year}>{year}</Select.Option>
          ))}
        </Select>
      </div>

      {categories.map((category, index) => (
        <Collapse key={category.name}>
          <CollapsePanel header={<>{category.name}</>} key={index}>
            {category.component}
          </CollapsePanel>
        </Collapse>
      ))}
    </Space>
  )
}

export default Data
