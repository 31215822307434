const localStorageGet = (name: string) => {
  if (navigator.cookieEnabled) {
    const data = localStorage.getItem(name)

    if (!data) return ''

    return JSON.parse(data)
  }

  return ''
}

const localStorageSave = (name: string, value: any) => {
  if (navigator.cookieEnabled) {
    localStorage.setItem(name, JSON.stringify(value))
  }
}

const localStorageDelete = (name: string) => {
  if (navigator.cookieEnabled) {
    localStorage.removeItem(name)
  }
}

export { localStorageGet, localStorageSave, localStorageDelete }
