import { Form, InputNumber, Select } from 'antd'
import { useWatch } from 'antd/lib/form/Form'
import { useEffect, useState } from 'react'
import { CURRENT_YEAR } from '../../constants/default-values'
import { dollarFormatter, parser } from '../../constants/input-number-formats'
import FederalTaxEngine from '../../types/federal-tax-engine'
import { currency } from '../../utils/currency'
import getSavedFederalTaxEngine from '../../utils/get-saved-federal-tax-engine'
import FederalTaxEngineEditor from '../shared/federal-tax-engine-editor'
import FteComparison from '../shared/fte-comparison'
import CalculatorNotes from '../shared/notes'
import CalculatorResults from '../shared/results'
import CalculatorShell from '../shared/shell'
import offsetMedicareWagesAndTips from '../shared/utils/offset-medicare-wages-and-tips'
const { Option } = Select

const Section125PlanContribution = () => {
  const [form] = Form.useForm()
  const filingYear = useWatch('filingYear', form)
  const plan125Contribution = useWatch('plan125Contribution', form)
  const [modalVisible, setModalVisible] = useState(false)
  const [preTax, setPreTax] = useState(0)
  const [postEngine, setPostEngine] = useState<FederalTaxEngine>(
    new FederalTaxEngine()
  )

  useEffect(() => {
    const engine = getSavedFederalTaxEngine(filingYear)
    engine.calculate()
    setPreTax(engine.results.estimatedTotalTaxes)
  }, [modalVisible, filingYear])

  useEffect(() => {
    if (plan125Contribution >= 0) {
      let engine = getSavedFederalTaxEngine(filingYear)
      engine = offsetMedicareWagesAndTips(engine, plan125Contribution)
      engine.calculate()
      setPostEngine(engine)
    }
  }, [plan125Contribution, filingYear, modalVisible])

  return (
    <CalculatorShell title='Section 125 Plan Contribution' usesFte>
      <Form
        form={form}
        layout='vertical'
        initialValues={{
          filingYear: CURRENT_YEAR,
          plan125Contribution: 0
        }}
      >
        <Form.Item label='Filing Year' name='filingYear'>
          <Select placeholder='Select a filing year'>
            {[2021, 2022].map((year, index) => (
              <Option key={index} value={year}>
                {year}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <FederalTaxEngineEditor
            type='link'
            filingYear={filingYear}
            onModalViewChange={(visible) => setModalVisible(visible)}
          />
        </Form.Item>
        <Form.Item
          label={`Amount of ${filingYear} qualified Section 125 plan contributions for eligible expenses:`}
          name='plan125Contribution'
        >
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item label={`${filingYear} pre-contribution federal tax:`}>
          <InputNumber
            disabled
            value={preTax}
            formatter={dollarFormatter}
            parser={parser}
          />
        </Form.Item>
        <Form.Item label={`${filingYear} post-contribution federal tax:`}>
          <InputNumber
            disabled
            value={postEngine.results.estimatedTotalTaxes}
            formatter={dollarFormatter}
            parser={parser}
          />
        </Form.Item>
      </Form>
      <CalculatorResults
        results={[
          {
            title: 'Cash Flow',
            content: currency.format(0)
          },
          {
            title: 'Current Year Tax Flow',
            content:
              plan125Contribution === 0
                ? currency.format(0)
                : currency.format(
                  preTax - postEngine.results.estimatedTotalTaxes
                )
          },
          {
            title: 'Expected Net Tax Flow',
            boldTitle: true,
            content:
              plan125Contribution === 0
                ? currency.format(0)
                : currency.format(
                  preTax - postEngine.results.estimatedTotalTaxes
                )
          }
        ]}
      >
        <CalculatorNotes
          notes={[
            'Calculation assumes taxpayer has eligible income to participate in a Section 125 Plan'
          ]}
        />
        <FteComparison filingYear={filingYear} results={postEngine} />
      </CalculatorResults>
    </CalculatorShell>
  )
}

export default Section125PlanContribution
