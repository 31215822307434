import { Form, InputNumber } from 'antd'
import { useWatch } from 'antd/lib/form/Form'
import {
  dollarFormatter,
  numberFormatter,
  parser,
  percentageFormatter
} from '../../constants/input-number-formats'
import CalculatorNotes from '../shared/notes'
import CalculatorResults from '../shared/results'
import CalculatorShell from '../shared/shell'

const Plan529Contribution = () => {
  const [form] = Form.useForm()
  const plan529Contribution = useWatch('plan529Contribution', form)
  const years = useWatch('years', form)
  const expectedAnnualRoi = useWatch('expectedAnnualRoi', form)

  return (
    <CalculatorShell title='529 Plan Contribution'>
      <Form
        form={form}
        layout='vertical'
        initialValues={{
          plan529Contribution: 0,
          years: 0,
          expectedAnnualRoi: 2.0
        }}
      >
        <Form.Item label='529 Plan Contribution:' name='plan529Contribution'>
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item
          label='Years until funds distributed for qualified educational expenses:'
          name='years'
        >
          <InputNumber min={0} step={1} formatter={numberFormatter} />
        </Form.Item>
        <Form.Item
          label='Expected annual rate of return on investment:'
          name='expectedAnnualRoi'
        >
          <InputNumber
            min={2}
            max={25}
            step={0.25}
            formatter={percentageFormatter}
          />
        </Form.Item>
      </Form>
      <CalculatorResults
        results={[
          {
            title: 'Cash Flow',
            currencyResult: -1 * plan529Contribution
          },
          {
            title: 'Current Year Tax Flow',
            currencyResult: 0
          },
          {
            title: 'Expected Future Years Cash Flow',
            currencyResult:
              plan529Contribution * (1 + expectedAnnualRoi / 100) ** years
          },
          {
            title: 'Expected Net Tax Flow',
            boldTitle: true,
            currencyResult:
              plan529Contribution * (1 + expectedAnnualRoi / 100) ** years +
              -1 * plan529Contribution
          }
        ]}
      >
        <CalculatorNotes
          notes={[
            'Potential future years tax flow represents tax free growth and penalty free distributions'
          ]}
        />
      </CalculatorResults>
    </CalculatorShell>
  )
}

export default Plan529Contribution
