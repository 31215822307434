import { Typography } from 'antd'
import { currency } from '../../utils/currency'
const { Text } = Typography

const PositiveNegativeIndicator = ({ value }: { value: number }) => {
  const zero = Math.abs(value) === 0

  return (
    <Text
      style={{
        color: zero ? '#9FA6B2' : value > 0 ? 'black' : '#DC4C64',
        opacity: zero || value < 0 ? 1 : 1
      }}
    >
      {zero ? currency.format(0) : currency.format(value)}
    </Text>
  )
}

export default PositiveNegativeIndicator
