import { Alert, Card } from 'antd'
import useMediaQuery from 'use-media-antd-query'
const RestrictedArea = () => {
  const colSize = useMediaQuery()

  return (
    <Card
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        minHeight: '50vh',
        marginTop: '15vh',
        width: '50vw',
        marginLeft: '25vw'
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center'
        }}
      >
        <img
          src={`./teaching-tax-flow-${
            ['xs', 'sm'].includes(colSize) ? 'small' : 'large'
          }.png`}
          alt=''
          style={{
            maxWidth: '75%',
            height: 'auto',
            marginBottom: '4em'
          }}
        />
        <Alert
          type='error'
          description={
            <h3 style={{ maxWidth: 350 }}>
              You have attempted to reach a page that is currently restricted to
              administrators only.
            </h3>
          }
        />
      </div>
    </Card>
  )
}
export default RestrictedArea
