import { TFilingBracketTier, TFilingStatusTaxBracket } from './types'

const tiers2021: TFilingBracketTier[] = [
  {
    rate: 0.1,
    min: 0,
    max: 14200,
    fixedTaxAmount: 0
  },
  {
    rate: 0.12,
    min: 14201,
    max: 54200,
    fixedTaxAmount: 1420
  },
  {
    rate: 0.22,
    min: 54201,
    max: 86350,
    fixedTaxAmount: 6220
  },
  {
    rate: 0.24,
    min: 86351,
    max: 164900,
    fixedTaxAmount: 13293
  },
  {
    rate: 0.32,
    min: 164901,
    max: 209400,
    fixedTaxAmount: 32145
  },
  {
    rate: 0.35,
    min: 209401,
    max: 523600,
    fixedTaxAmount: 46385
  },
  {
    rate: 0.37,
    min: 523601,
    max: Infinity,
    fixedTaxAmount: 156355
  }
]

const tiers2022: TFilingBracketTier[] = [
  {
    rate: 0.1,
    min: 0,
    max: 14650,
    fixedTaxAmount: 0
  },
  {
    rate: 0.12,
    min: 14651,
    max: 55900,
    fixedTaxAmount: 1465
  },
  {
    rate: 0.22,
    min: 55901,
    max: 89050,
    fixedTaxAmount: 6415
  },
  {
    rate: 0.24,
    min: 89051,
    max: 170050,
    fixedTaxAmount: 13708
  },
  {
    rate: 0.32,
    min: 170051,
    max: 215950,
    fixedTaxAmount: 33148
  },
  {
    rate: 0.35,
    min: 215951,
    max: 539900,
    fixedTaxAmount: 47836
  },
  {
    rate: 0.37,
    min: 539901,
    max: Infinity,
    fixedTaxAmount: 161218.5
  }
]

const headOfHouseholdQualifiedWidow: TFilingStatusTaxBracket = {
  filingStatusCodes: ['hh', 'qw'],
  years: [
    {
      year: 2021,
      tiers: tiers2021,
      standardDeduction: 18800
    },
    {
      year: 2022,
      tiers: tiers2022,
      standardDeduction: 19400
    }
  ]
}

export default headOfHouseholdQualifiedWidow
