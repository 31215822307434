import { CURRENT_YEAR } from './default-values'
import headOfHouseholdQualifiedWidow from './federal-tax-tables/head-of-household-qualified-widow'
import marriedFilingJointly from './federal-tax-tables/married-filing-jointly'
import marriedFilingSeperately from './federal-tax-tables/married-filing-seperately'
import single from './federal-tax-tables/single'
import { TFilingStatusTaxBracket } from './federal-tax-tables/types'
import { TFilingStatusCode } from './filing-status'

const getBaseFederalIncomeTax = (
  filingStatusCode: TFilingStatusCode,
  filingYear: number = CURRENT_YEAR,
  taxableIncome: number
) => {
  let taxBracket: TFilingStatusTaxBracket
  switch (filingStatusCode) {
    case 's':
      taxBracket = single
      break
    case 'hh':
      taxBracket = headOfHouseholdQualifiedWidow
      break
    case 'qw':
      taxBracket = headOfHouseholdQualifiedWidow
      break
    case 'mfs':
      taxBracket = marriedFilingSeperately
      break
    case 'mfj':
      taxBracket = marriedFilingJointly
      break
  }

  if (taxBracket && taxableIncome > 0) {
    const bracketYear = taxBracket.years.find(
      (year) => year.year === filingYear
    )!
    const { rate, fixedTaxAmount, min } = bracketYear.tiers.find(
      (t) => taxableIncome >= t.min && taxableIncome <= t.max
    )!
    return {
      rate,
      fixedTaxAmount,
      floorAmount: min
    }
  }

  return {
    rate: 0,
    fixedTaxAmount: 0,
    floorAmount: 0
  }
}

export { getBaseFederalIncomeTax }
