import { Modal } from 'antd'
import { useEffect, useState } from 'react'
import { BsCalculator } from 'react-icons/bs'
import { currency } from '../../../utils/currency'
import { localStorageGet } from '../../../utils/local-storage'
import useMediaQuery from 'use-media-antd-query'
import FederalTaxEngineCalculator from '../../federal-tax-engine'
import { TFilingStatusCode } from '../../../constants/filing-status'

interface Props {
  filingYear: number
  type?: 'link' | 'modal'
  hideTitle?: boolean
  onEstimatedTotalChange?: (amount: number) => void
  onFilingStatusChange?: (filingStatusCode: TFilingStatusCode) => void
  onModalViewChange?: (visible: boolean) => void
}

const FederalTaxEngineEditor: React.FC<Props> = ({
  filingYear,
  type = 'modal',
  onEstimatedTotalChange,
  onFilingStatusChange,
  onModalViewChange,
  hideTitle = false
}) => {
  const [showModal, setShowModal] = useState(false)
  const [filingStatusCode, setFilingStatusCode] =
    useState<TFilingStatusCode>('s')
  const [preTax, setPreTax] = useState(0)
  const colSize = useMediaQuery()

  useEffect(() => {
    const taxEngineResults = localStorageGet(
      `federal-tax-engine-${filingYear}`
    )
    setPreTax(taxEngineResults ? taxEngineResults?.estimatedTotalTaxes : 0)
    setFilingStatusCode(
      taxEngineResults ? taxEngineResults?.filingStatusCode : 's'
    )
  }, [showModal, filingYear])

  useEffect(() => {
    if (onFilingStatusChange != null) {
      onFilingStatusChange(filingStatusCode)
    }

    if (onEstimatedTotalChange != null) {
      onEstimatedTotalChange(preTax)
    }
  }, [filingStatusCode, preTax])

  useEffect(() => {
    (onModalViewChange != null) && onModalViewChange(showModal)
  }, [showModal])

  const modal = (
    <Modal
      visible
      onCancel={() => setShowModal(false)}
      centered
      style={{
        marginTop: ['xs', 'sm'].includes(colSize) ? 12 : 24,
        marginBottom: ['xs', 'sm'].includes(colSize) ? 12 : 24
      }}
      width={['xs', 'sm'].includes(colSize) ? '100%' : '75%'}
      footer={null}
      maskStyle={{
        backgroundColor:
          '#932290' /* For browsers that do not support gradients */,
        backgroundImage: 'linear-gradient(#932290, #662992)'
      }}
    >
      <div style={{ marginTop: 20 }} />
      <FederalTaxEngineCalculator
        modal
        year={filingYear}
        onDismiss={(type) => setShowModal(false)}
      />
    </Modal>
  )

  if (type === 'link') {
    return (
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <span>
          {hideTitle ? null : <span>Calculations influenced by the </span>}
          <a
            href='_'
            onClick={(e) => {
              e.preventDefault()
              setShowModal(true)
            }}
          >
            <i>{filingYear} Federal Tax Engine</i>
          </a>
        </span>
        {showModal && modal}
      </div>
    )
  }

  return (
    <div>
      <b>{filingYear}</b>
      <div
        style={{
          display: 'inline-block',
          padding: '2px 3px 0px 3px',
          border: '1px solid gray',
          borderRadius: '10%',
          marginLeft: 5,
          cursor: 'pointer'
        }}
        title='Edit'
        onClick={() => setShowModal(true)}
      >
        <BsCalculator />
      </div>
      <br />
      <span>{currency.format(preTax)}</span>
      {showModal && modal}
    </div>
  )
}

export default FederalTaxEngineEditor
