import { Col, Form, Row, Select } from 'antd'
import { useWatch } from 'antd/lib/form/Form'
import { useEffect, useState } from 'react'
import TtfDiagnosis from '../../components/ttf-diagnosis'
import { CURRENT_YEAR } from '../../constants/default-values'
import { TFilingStatusCode } from '../../constants/filing-status'
import { getFicaCeilingHit } from '../../constants/self-employment-rates'
import { localStorageGet, localStorageSave } from '../../utils/local-storage'
import FederalTaxEngineEditor from '../shared/federal-tax-engine-editor'
import CalculatorNotes from '../shared/notes'
import CalculatorResults from '../shared/results'
import CalculatorShell from '../shared/shell'
import { TTtfDiagnosis } from './diagnosis'
const { Option } = Select

const MarginalTaxRate = () => {
  const [form] = Form.useForm()
  const filingYear = useWatch('filingYear', form)
  const mtrPrediction = useWatch('mtrPrediction', form)
  const [rates, setRates] = useState({
    marginalTax: 0,
    ltcgTax: 0,
    additionalMedicareTax: 0,
    netInvestmentIncomeTax: 0,
    selfEmploymentTax: {
      ficaRate: 0,
      medicareRate: 0
    },
    spouseSelfEmploymentTax: {
      ficaRate: 0,
      medicareRate: 0
    }
  })
  const [diagnoses, setDiagnoses] = useState<TTtfDiagnosis[]>(['Gold'])
  const [taxEngineVisible, setTaxEngineVisible] = useState(false)
  const [selfEmployment, setSelfEmployment] = useState({
    self: false,
    spouse: false
  })
  const [hideMtrDropdown, setHideMtrDropdown] = useState(false)
  const [userSavedFte, setUserSavedFte] = useState(false)
  const [filingStatusCode, setFilingStatusCode] = useState<TFilingStatusCode>()

  useEffect(() => {
    if (filingYear) {
      const {
        filingStatusCode,
        federalIncomeTaxRate,
        capitalGainsTaxRate,
        additionalMedicareTax,
        netInvestmentIncomeTax,
        selfEmployedMedicareTax: {
          self: selfEmployedMedicareTax,
          spouse: selfEmployedMedicareTaxSpouse
        },
        selfEmployment,
        selfEmploymentIncome,
        spouseSelfEmployment,
        spouseSelfEmploymentIncome,
        medicareWagesAndTips,
        spouseMedicareWagesAndTips
      } = localStorageGet(`federal-tax-engine-${filingYear}`)

      const userSaved = localStorageGet(
        `federal-tax-engine-${filingYear}-user-saved`
      )

      if (!userSaved) {
        setDiagnoses(['Purple', 'Gold'])
        return
      } else {
        setUserSavedFte(true)
      }

      setRates({
        marginalTax: federalIncomeTaxRate,
        ltcgTax: capitalGainsTaxRate,
        additionalMedicareTax: additionalMedicareTax > 0 ? 0.009 : 0,
        netInvestmentIncomeTax: netInvestmentIncomeTax > 0 ? 0.038 : 0,
        selfEmploymentTax: {
          ficaRate: getFicaCeilingHit(
            medicareWagesAndTips,
            selfEmploymentIncome,
            filingYear
          )
            ? 0
            : 0.124,
          medicareRate: selfEmployedMedicareTax > 0 ? 0.029 : 0
        },
        spouseSelfEmploymentTax: {
          ficaRate: getFicaCeilingHit(
            spouseMedicareWagesAndTips,
            spouseSelfEmploymentIncome,
            filingYear
          )
            ? 0
            : 0.124,
          medicareRate: selfEmployedMedicareTaxSpouse > 0 ? 0.029 : 0
        }
      })

      setSelfEmployment({
        self: selfEmployment,
        spouse: spouseSelfEmployment
      })

      setFilingStatusCode(filingStatusCode)

      form.setFieldsValue({
        mtrPrediction: localStorageGet(`mtrPrediction-${filingYear}`) || null
      })
    }
  }, [form, filingYear, taxEngineVisible])

  useEffect(() => {
    let diagnoses: TTtfDiagnosis[] = []
    if (rates.marginalTax >= 0.25) {
      // lower
      diagnoses = ['Red', 'Gold']
      setHideMtrDropdown(true)
    } else if (rates.marginalTax <= 0.2) {
      // higher
      diagnoses = ['Green', 'Gold']
      setHideMtrDropdown(true)
    } else {
      if (mtrPrediction === 'Lower') {
        diagnoses = ['Green', 'Gold']
      } else if (mtrPrediction === 'Higher') {
        diagnoses = ['Red', 'Gold']
      } else {
        diagnoses = ['Purple', 'Gold']
      }
      setHideMtrDropdown(false)
    }

    setDiagnoses(diagnoses)
  }, [rates, mtrPrediction])

  useEffect(() => {
    if (mtrPrediction) {
      localStorageSave(`mtrPrediction-${filingYear}`, mtrPrediction)
    }
  }, [mtrPrediction])

  return (
    <CalculatorShell title='Marginal Tax Rate (MTR)'>
      <Form
        form={form}
        layout='vertical'
        initialValues={{
          filingYear: CURRENT_YEAR,
          mtrPrediction: 'Same'
        }}
      >
        <Form.Item label='Filing year:' name='filingYear'>
          <Select>
            {[2021, 2022].map((year, index) => (
              <Option key={index} value={year}>
                {year}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='How do you expect your future MTR to compare to your current MTR?'
          name='mtrPrediction'
          style={{ display: hideMtrDropdown ? 'none' : 'inherit' }}
        >
          <Select style={{ minWidth: 250 }} placeholder='Choose an expectation'>
            {['Lower', 'Same', 'Higher'].map((year, index) => (
              <Option key={index} value={year}>
                {year}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {userSavedFte
          ? (
            <Form.Item>
              <FederalTaxEngineEditor
                type='link'
                filingYear={filingYear}
                onModalViewChange={(visible) => setTaxEngineVisible(visible)}
              />
            </Form.Item>
            )
          : null}
      </Form>
      {userSavedFte
        ? (
          <>
            <CalculatorResults
              showDivider={false}
              results={[
                {
                  title: 'Marginal Tax Bracket',
                  content: parseFloat((rates.marginalTax * 100).toFixed(2)) + '%'
                },
                {
                  title: 'Long-term Capital Gains Tax Rate',
                  content: parseFloat((rates.ltcgTax * 100).toFixed(2)) + '%'
                },

                {
                  title: 'Additional Medicare Tax Rate (Form 8959)',
                  content:
                  parseFloat((rates.additionalMedicareTax * 100).toFixed(2)) +
                  '%'
                },

                {
                  title: 'Net Investment Income Tax Rate (Form 8960)',
                  content:
                  parseFloat((rates.netInvestmentIncomeTax * 100).toFixed(2)) +
                  '%'
                },
                ...(selfEmployment.self
                  ? [
                      {
                        title: 'Self-Employment Tax - Social Security',
                        content:
                        parseFloat(
                          (rates.selfEmploymentTax.ficaRate * 100).toFixed(2)
                        ) + '%'
                      },
                      {
                        title: 'Self-Employment Tax - Medicare',
                        content:
                        parseFloat(
                          (rates.selfEmploymentTax.medicareRate * 100).toFixed(
                            2
                          )
                        ) + '%'
                      }
                    ]
                  : []),
                ...(filingStatusCode === 's' || !selfEmployment.spouse
                  ? []
                  : [
                      {
                        title: 'Spouse Self-Employment Tax - Social Security',
                        content:
                        parseFloat(
                          (
                            rates.spouseSelfEmploymentTax.ficaRate * 100
                          ).toFixed(2)
                        ) + '%'
                      },
                      {
                        title: 'Spouse Self-Employment Tax - Medicare',
                        content:
                        parseFloat(
                          (
                            rates.spouseSelfEmploymentTax.medicareRate * 100
                          ).toFixed(2)
                        ) + '%'
                      }
                    ]),
                {
                  title: 'MTR for Wages',
                  content:
                  parseFloat(
                    (
                      (rates.marginalTax + rates.additionalMedicareTax) *
                      100
                    ).toFixed(2)
                  ) + '%',
                  dividerBefore: true
                },
                ...(selfEmployment.self
                  ? [
                      {
                        title: 'MTR for Self-Employment Income',
                        content:
                        parseFloat(
                          (
                            (rates.marginalTax +
                              rates.selfEmploymentTax.ficaRate +
                              rates.selfEmploymentTax.medicareRate) *
                            100
                          ).toFixed(2)
                        ) + '%'
                      }
                    ]
                  : []),
                ...(filingStatusCode === 's' || !selfEmployment.spouse
                  ? []
                  : [
                      {
                        title: "Spouse's MTR for Self-Employment Income",
                        content:
                        parseFloat(
                          (
                            (rates.marginalTax +
                              rates.spouseSelfEmploymentTax.ficaRate +
                              rates.spouseSelfEmploymentTax.medicareRate) *
                            100
                          ).toFixed(2)
                        ) + '%'
                      }
                    ]),
                {
                  title: 'MTR for Investment Income',
                  content:
                  parseFloat(
                    (
                      (rates.marginalTax + rates.netInvestmentIncomeTax) *
                      100
                    ).toFixed(2)
                  ) + '%'
                },
                {
                  title:
                  'MTR for Long-term Capital Gains and Qualified Dividends',
                  content:
                  parseFloat(
                    (
                      (rates.ltcgTax + rates.netInvestmentIncomeTax) *
                      100
                    ).toFixed(2)
                  ) + '%'
                }
              ]}
            />
          </>
          )
        : null}
      <Row style={{ marginTop: 25 }}>
        <Col xs={24} md={12}>
          {mtrPrediction
            ? (
              <TtfDiagnosis
                filingYear={filingYear}
                userSavedFte={userSavedFte}
                diagnoses={diagnoses}
                onFteModalViewChange={(visible) => setTaxEngineVisible(visible)}
              />
              )
            : null}
          {userSavedFte
            ? (
              <CalculatorNotes
                notes='Calculations do not factor in deductions for QBI or 1/2 self-employment tax nor alternative minimum tax'
              />
              )
            : null}
        </Col>
      </Row>
    </CalculatorShell>
  )
}

export default MarginalTaxRate
