import { Modal, Tooltip, Typography } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'
import useMediaQuery from 'use-media-antd-query'
import { getVideoById } from './utils'

const StyledContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  margin-top: -12px;
  height: 0;
  overflow: hidden;
  user-select: none;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const StyledLink = styled.span`
  transition: all 0.2s ease-in-out;

  img {
    cursor: pointer;
  }

  img:hover {
    box-shadow: 1px 3px 3px #000;
    border-radius: 100%;
    transform: scale(1.1);
    border: 1px solid white;
  }
`

const openInNewTab = (url: string) => {
  window.open(url, '_blank')
}

const colSizeMapping = {
  xs: '100%',
  sm: '100%',
  md: '100%',
  lg: '90%',
  xl: '85%',
  xxl: '75%'
}

const VideoModal: React.FC<{ id: string }> = ({ id }) => {
  const [showModal, setShowModal] = useState(false)
  const colSize = useMediaQuery()
  const { title, link, code } = getVideoById(id)

  return (
    <>
      <Tooltip title='Tutorial' mouseEnterDelay={0.75} placement='right'>
        <StyledLink
          onClick={() => {
            setShowModal(true)
          }}
        >
          <img src='../info-icon.png' alt='ttf-info-icon' width={15} />
        </StyledLink>
      </Tooltip>
      <Modal
        visible={showModal}
        centered
        onCancel={() => setShowModal(false)}
        destroyOnClose
        width={colSizeMapping[colSize]}
        footer={
          <div style={{ textAlign: 'center' }}>
            <i>
              <a
                href={`./tutorials/${code}`}
                onClick={(event) => {
                  event.preventDefault()
                  setShowModal(true)
                  openInNewTab(`./tutorials/${code}`)
                }}
              >
                Click here
              </a>{' '}
              to view content in a new tab
            </i>
          </div>
        }
        title={
          <div style={{ textAlign: 'center' }}>
            <Typography.Title
              style={{ marginBottom: 0 }}
              level={['xs', 'sm'].includes(colSize) ? 4 : 3}
            >
              {title}
            </Typography.Title>
          </div>
        }
      >
        <StyledContainer>
          <video autoPlay={false} controls src={`../videos/${link}`} />
        </StyledContainer>
      </Modal>
    </>
  )
}

export default VideoModal
