import { QuestionCircleOutlined } from '@ant-design/icons'
import { Col, Divider, Form, Row, Tooltip } from 'antd'
import { PropsWithChildren } from 'react'
import PositiveNegativeIndicator from '../../../components/positive-negative-indicator'
import SignatureCard from '../signature-card'

interface ICalculatorResult {
  title: string
  content?: any
  explanationText?: string
  boldTitle?: boolean
  hide?: boolean
  dividerBefore?: boolean
  currencyResult?: number
}

interface Props {
  title?: string
  showDivider?: boolean
  results: ICalculatorResult[]
  colSize?: number
  titleColor?: string
  style?: React.CSSProperties
}

const CalculatorResults: React.FC<PropsWithChildren<Props>> = ({
  title = 'Results',
  showDivider = true,
  results,
  colSize,
  titleColor,
  style,
  children
}) => {
  if (!results) return null

  return (
    <Row>
      <Col xs={24} md={colSize || 12}>
        <SignatureCard text={title} color={titleColor} style={{ ...style }}>
          {results.map(
            (
              {
                title,
                content,
                explanationText,
                boldTitle,
                hide,
                dividerBefore,
                currencyResult
              },
              index
            ) => (
              <div key={index}>
                {dividerBefore
                  ? (
                    <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                    )
                  : null}
                {showDivider &&
                index + 1 === results.length &&
                results.length > 1
                  ? (
                    <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                    )
                  : null}
                <Form.Item
                  style={{
                    display: hide ? 'none' : 'inherit',
                    marginBottom: 0
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <span
                      style={{ fontWeight: boldTitle ? 'bold' : 'inherit' }}
                    >
                      {title}
                      {explanationText && (
                        <Tooltip
                          title={explanationText}
                          overlayInnerStyle={{
                            textAlign: 'center',
                            maxWidth: 300
                          }}
                        >
                          <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                        </Tooltip>
                      )}
                    </span>
                    <span
                      style={{
                        marginLeft: 5
                      }}
                    >
                      {typeof currencyResult === 'number'
                        ? (
                          <PositiveNegativeIndicator value={currencyResult} />
                          )
                        : (
                            content
                          )}
                    </span>
                  </div>
                </Form.Item>
              </div>
            )
          )}
        </SignatureCard>
        {children}
      </Col>
    </Row>
  )
}

export default CalculatorResults
