import { InfoCircleOutlined } from '@ant-design/icons'
import { Switch, Table, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import FederalTaxEngine from '../../../types/federal-tax-engine'
import { currency } from '../../../utils/currency'
import getSavedFederalTaxEngine from '../../../utils/get-saved-federal-tax-engine'
import inIframe from '../../../utils/in-iframe'

interface Props {
  filingYear: number
  results: FederalTaxEngine
}

interface ComparedValue {
  name: string
  before: number
  after: number
  difference: number
  direction: 'up' | 'down' | 'same'
}

const FteComparison: React.FC<Props> = ({ filingYear, results }) => {
  const [show, setShow] = useState(false)
  const [comparisons, setComparisons] = useState<ComparedValue[]>([])

  useEffect(() => {
    const comparisons: ComparedValue[] = []
    const preParamsAndResults =
      getSavedFederalTaxEngine(filingYear).getParemetersAndResults()
    const postParamsAndResults = results.getParemetersAndResults()

    Object.keys(preParamsAndResults).forEach((name) => {
      // @ts-expect-error
      const before = preParamsAndResults[name] as number
      // @ts-expect-error
      const after = postParamsAndResults[name] as number
      if (before !== undefined && after !== undefined) {
        const direction =
          before === after ? 'same' : after > before ? 'up' : 'down'
        if (direction !== 'same') {
          comparisons.push({
            name,
            before,
            after,
            direction,
            difference: after - before
          })
        }
      }
    })

    setComparisons(comparisons)
  }, [filingYear, results])

  if (comparisons.length === 0 || inIframe()) {
    return null
  }

  return (
    <>
      <div style={{ padding: 12, display: 'flex', alignItems: 'center' }}>
        <Switch
          size='small'
          checked={show}
          onChange={() => setShow((show) => !show)}
        />
        <small style={{ marginLeft: 5, marginRight: 5 }}>{`${
          show ? 'Hide' : 'View'
        } Federal Tax Engine Comparison`}
        </small>
        <Tooltip
          overlayInnerStyle={{ textAlign: 'center' }}
          title='FTE values altered by above inputs'
        >
          <InfoCircleOutlined style={{ cursor: 'pointer' }} />
        </Tooltip>
      </div>
      {show
        ? (
          <Table
            bordered
            className='custom-antd-table-header'
            pagination={false}
            dataSource={comparisons}
            columns={[
              {
                key: '1',
                dataIndex: 'name',
                title: 'Engine Value',
                width: '40%',
                render: (_, { name }) => <small>{name}</small>
              },
              {
                key: '2',
                dataIndex: 'before',
                title: 'Pre-Adjustments',
                width: '22.5%',
                render: (_, { before, name }) => {
                  return (
                    <small style={{ float: 'right' }}>
                      {name.toLowerCase().includes('rate')
                        ? ((before * 100).toFixed(2) + '%').replace('.00%', '%')
                        : currency.format(before)}
                    </small>
                  )
                }
              },
              {
                key: '3',
                dataIndex: 'after',
                title: 'Post-Adjustments',
                width: '22.5%',
                render: (_, { after, name }) => {
                  return (
                    <small style={{ float: 'right' }}>
                      {name.toLowerCase().includes('rate')
                        ? ((after * 100).toFixed(2) + '%').replace('.00%', '%')
                        : currency.format(after)}
                    </small>
                  )
                }
              },
              {
                key: '4',
                dataIndex: 'direction',
                title: 'Change',
                width: '15%',
                render: (_, { difference, name }) => {
                  return (
                    <small style={{ float: 'right' }}>
                      {difference === 0
                        ? '-'
                        : name.toLowerCase().includes('rate')
                          ? ((difference * 100).toFixed(2) + '%').replace(
                              '.00%',
                              '%'
                            )
                          : currency.format(difference)}
                    </small>
                  )
                }
              }
            ]}
          />
          )
        : null}
    </>
  )
}

export default FteComparison
