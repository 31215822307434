import { Form, InputNumber, Select } from 'antd'
import { useForm, useWatch } from 'antd/lib/form/Form'
import { useEffect, useState } from 'react'
import VideoLabel from '../../components/video-label/VideoLabel'
import { CURRENT_YEAR } from '../../constants/default-values'
import { dollarFormatter, parser } from '../../constants/input-number-formats'
import { currency } from '../../utils/currency'
import getSavedFederalTaxEngine from '../../utils/get-saved-federal-tax-engine'
import FederalTaxEngineEditor from '../shared/federal-tax-engine-editor'
import CalculatorNotes from '../shared/notes'
import CalculatorResults from '../shared/results'
import CalculatorShell from '../shared/shell'
const { Option } = Select

const FederalIncomeTaxBalanceDueOrOverpayment = () => {
  const [form] = useForm()
  const filingYear = useWatch('filingYear', form)
  const previousYearTaxApplied = useWatch('previousYearTaxApplied', form)
  const taxWithholding = useWatch('taxWithholding', form)
  const estimatedTaxPayments = useWatch('estimatedTaxPayments', form)
  const [modalVisible, setModalVisible] = useState(false)
  const [preTax, setPreTax] = useState(0)
  const result =
    preTax -
    (previousYearTaxApplied + taxWithholding + estimatedTaxPayments || 0)

  useEffect(() => {
    const engine = getSavedFederalTaxEngine(filingYear)
    setPreTax(engine.results.estimatedTotalTaxes)
  }, [filingYear, modalVisible])

  return (
    <CalculatorShell title='Federal Income Tax Balance Due or (Overpayment)' usesFte>
      <Form
        form={form}
        layout='vertical'
        initialValues={{
          filingYear: CURRENT_YEAR,
          previousYearTaxApplied: 0,
          taxWithholding: 0,
          estimatedTaxPayments: 0
        }}
      >
        <Form.Item label='Filing year:' name='filingYear'>
          <Select placeholder='Select a filing year'>
            {[2021, 2022].map((year, index) => (
              <Option key={index} value={year}>
                {year}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <FederalTaxEngineEditor
            type='link'
            filingYear={filingYear}
            onModalViewChange={(visible) => setModalVisible(visible)}
          />
        </Form.Item>
        <Form.Item label={`${filingYear} base federal income tax:`}>
          <InputNumber
            disabled
            value={preTax}
            formatter={dollarFormatter}
            parser={parser}
          />
        </Form.Item>
        <Form.Item
          label={
            <VideoLabel
              label='Federal estimated tax applied from prior year'
              videoId='10'
            />
          }
          name='previousYearTaxApplied'
        >
          <InputNumber formatter={dollarFormatter} parser={parser} min={0} />
        </Form.Item>
        <Form.Item
          label={
            <VideoLabel
              label='Federal tax withholding (i.e. W-2s, 1099-Rs)'
              videoId='12'
            />
          }
          name='taxWithholding'
        >
          <InputNumber formatter={dollarFormatter} parser={parser} min={0} />
        </Form.Item>
        <Form.Item
          label={
            <VideoLabel label='Federal estimated tax payments' videoId='11' />
          }
          name='estimatedTaxPayments'
        >
          <InputNumber formatter={dollarFormatter} parser={parser} min={0} />
        </Form.Item>
      </Form>
      <CalculatorResults
        results={[
          {
            title: 'Federal Tax Balance Due (Overpayment)',
            content:
              result >= 0
                ? currency.format(result)
                : `(${currency.format(Math.abs(result))})`
          }
        ]}
      >
        <CalculatorNotes notes=' Does not factor in potential late filing penalties or interest' />
      </CalculatorResults>
    </CalculatorShell>
  )
}

export default FederalIncomeTaxBalanceDueOrOverpayment
