import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from 'react'
import { CURRENT_YEAR } from '../../constants/default-values'
import { localStorageGet, localStorageSave } from '../../utils/local-storage'
import {
  FederalTaxEngineContextType,
  TFederalTaxEngineResults,
  generateDefaultResults
} from './types'

export const FederalTaxEngineContext =
  createContext<FederalTaxEngineContextType>({
    years: [],
    saveResults: () => {}
  })

export const useFteContext = () => useContext(FederalTaxEngineContext)

const FederalTaxEngineProvider: React.FC<PropsWithChildren<any>> = ({
  children
}) => {
  const [years, setYears] = useState<TFederalTaxEngineResults[]>([])

  useEffect(() => {
    let year = 2021
    const years: TFederalTaxEngineResults[] = []
    while (year <= CURRENT_YEAR) {
      const results = localStorageGet(`federal-tax-engine-${year}`)
      if (results) {
        years.push(results)
      } else {
        const defaultResults = generateDefaultResults(year)
        localStorageSave(`federal-tax-engine-${year}`, {
          ...defaultResults
        })
        localStorageSave(`federal-tax-engine-${year}-user-saved`, false)
        years.push(defaultResults)
      }
      year += 1
    }

    setYears(years)
  }, [])

  const saveResults = (results: TFederalTaxEngineResults) => {
    localStorageSave(`federal-tax-engine-${results.filingYear}`, {
      ...results
    })
    localStorageSave(
      `federal-tax-engine-${results.filingYear}-user-saved`,
      true
    )
    const updated = [
      ...years.filter((year) => year.filingYear !== results.filingYear)
    ].concat(results)
    setYears(updated)
  }

  return (
    <FederalTaxEngineContext.Provider value={{ years, saveResults }}>
      {children}
    </FederalTaxEngineContext.Provider>
  )
}

export default FederalTaxEngineProvider
