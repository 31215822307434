import { getTriggerYear } from '../../../constants/net-investment-income-tax-triggers'
import { statuses } from '../../../constants/filing-status'

const NetInvestmentIncomeTaxTriggers: React.FC<{ selectedYear: number }> = ({
  selectedYear
}) => {
  const bracketYearTiers = statuses.map((status) => ({
    status,
    data: getTriggerYear(status.code, selectedYear)
  }))

  if (bracketYearTiers.every((t) => t.data.threshold === 0)) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <h3>No data found for {selectedYear}</h3>
      </div>
    )
  }

  return (
    <div>
      <table border={1} style={{ whiteSpace: 'nowrap' }}>
        <thead style={{ backgroundColor: '#d3d3d3' }}>
          <th style={{ padding: 6 }}>Filing Status</th>
          <th style={{ padding: 6 }}>Income Threshold</th>
        </thead>
        {bracketYearTiers.map((tier) => (
          <tr>
            <td
              style={{
                paddingLeft: 10,
                paddingRight: 10
              }}
            >
              {tier.status.label}
            </td>
            <td
              style={{
                textAlign: 'right',
                paddingLeft: 10,
                paddingRight: 10
              }}
            >
              ${tier.data.threshold.toLocaleString()}
            </td>
          </tr>
        ))}
      </table>
    </div>
  )
}

export default NetInvestmentIncomeTaxTriggers
