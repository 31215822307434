import { TFilingStatusCode } from '../../constants/filing-status'

export interface TFederalTaxEngineResults {
  federalIncomeTaxRate: number
  federalIncomeTaxBase: number
  federalIncomeTaxExplanation: string
  capitalGainsTaxRate: number
  additionalMedicareTax: number
  additionalMedicareTaxExplanation: string
  netInvestmentIncomeTax: number
  netInvestmentIncomeTaxExplanation: string
  capitalGainsAndDividendsTax: number
  capitalGainsAndDividendsTaxExplanation: string
  selfEmployedFicaTax: TSelfEmployedTax
  selfEmployedFicaTaxExplanation: TSelfEmployedTaxExplanation
  selfEmployedMedicareTax: TSelfEmployedTax
  selfEmployedMedicareTaxExplanation: TSelfEmployedTaxExplanation
  estimatedTotalTaxes: number
  standardDeduction: number
  filingYear: number
  filingStatusCode: TFilingStatusCode
  federalTaxableIncome: number
  magi: number
  medicareWagesAndTips: number
  selfEmployment: boolean
  selfEmploymentIncome: number
  spouseMedicareWagesAndTips: number
  spouseSelfEmployment: boolean
  spouseSelfEmploymentIncome: number
  longTermCapitalGains: number
  qualifiedDividends: number
  investmentMagi: number
}

export interface TSelfEmployedTax {
  self: number
  spouse: number
}

export interface TSelfEmployedTaxExplanation {
  self: string
  spouse: string
}

export interface FederalTaxEngineContextType {
  years: TFederalTaxEngineResults[]
  saveResults: (deletingYear: TFederalTaxEngineResults) => void
}

const generateDefaultResults = (filingYear: number): TFederalTaxEngineResults => {
  return {
    federalIncomeTaxRate: 0,
    federalIncomeTaxBase: 0,
    federalIncomeTaxExplanation: '',
    capitalGainsTaxRate: 0,
    additionalMedicareTax: 0,
    additionalMedicareTaxExplanation: '',
    netInvestmentIncomeTax: 0,
    netInvestmentIncomeTaxExplanation: '',
    capitalGainsAndDividendsTax: 0,
    capitalGainsAndDividendsTaxExplanation: '',
    selfEmployedFicaTax: {
      self: 0,
      spouse: 0
    },
    selfEmployedFicaTaxExplanation: {
      self: '',
      spouse: ''
    },
    selfEmployedMedicareTax: {
      self: 0,
      spouse: 0
    },
    selfEmployedMedicareTaxExplanation: {
      self: '',
      spouse: ''
    },
    estimatedTotalTaxes: 0,
    standardDeduction: 0,
    filingYear,
    filingStatusCode: 's',
    federalTaxableIncome: 0,
    magi: 0,
    medicareWagesAndTips: 0,
    selfEmployment: false,
    selfEmploymentIncome: 0,
    spouseMedicareWagesAndTips: 0,
    spouseSelfEmployment: false,
    spouseSelfEmploymentIncome: 0,
    longTermCapitalGains: 0,
    qualifiedDividends: 0,
    investmentMagi: 0
  }
}

export { generateDefaultResults }
