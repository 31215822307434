export type TTtfDiagnosis = 'Red' | 'Green' | 'Purple' | 'Gold'

const getTtfDiagnosis = new Map<
TTtfDiagnosis,
{ color: string, description: JSX.Element }
>([
  [
    'Red',
    {
      color: '#dc3545',
      description: (
        <>
          Taxpayer currently in a high MTR or higher than expected future MTR
          and can benefit from an <b>immediate, one-time tax deduction</b>.
        </>
      )
    }
  ],
  [
    'Green',
    {
      color: '#198754',
      description: (
        <>
          Taxpayer currently in a low MTR or lower than expected future MTR and
          can benefit from <b>taxable income acceleration</b>.
        </>
      )
    }
  ],
  [
    'Purple',
    {
      color: '#6f42c1',
      description: (
        <>
          Taxpayer currently can benefit from <b>taxable income deferral</b>.
        </>
      )
    }
  ],
  [
    'Gold',
    {
      color: '#ffc107',
      description: (
        <>
          Taxpayer currently can benefit from{' '}
          <b>tax free income and/or asset growth</b>.
        </>
      )
    }
  ]
])

export { getTtfDiagnosis }
