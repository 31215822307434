import { Form, InputNumber, Select } from 'antd'
import { useWatch } from 'antd/lib/form/Form'
import { currency } from '../../utils/currency'
import CalculatorNotes from '../shared/notes'
import CalculatorResults from '../shared/results'
import CalculatorShell from '../shared/shell'
import {
  dollarFormatter,
  parser,
  percentageFormatter
} from '../../constants/input-number-formats'
import { useEffect, useState } from 'react'
import { CURRENT_YEAR } from '../../constants/default-values'
import FederalTaxEngine from '../../types/federal-tax-engine'
import getSavedFederalTaxEngine from '../../utils/get-saved-federal-tax-engine'
import FederalTaxEngineEditor from '../shared/federal-tax-engine-editor'
import FteComparison from '../shared/fte-comparison'
import {
  TOffsetIncomeType,
  getOffsetIncomeTypes,
  applyOffsettingIncome
} from '../shared/utils/offset-income-types'
import getAdjustedQualifyingAssets from './section-179-tiers'
import {
  IncomeReductionType,
  incomeReductionType
} from '../../types/income-reduction'
import VideoLabel from '../../components/video-label/VideoLabel'
const { Option } = Select

const Section179Deduction = () => {
  const [reducingIncomeTypeForm] = Form.useForm()
  const reducingIncomeType = useWatch(
    'reducingIncomeType',
    reducingIncomeTypeForm
  )

  return (
    <CalculatorShell title='Section 179 Deduction' usesFte>
      <Form
        form={reducingIncomeTypeForm}
        layout='vertical'
        initialValues={{
          reducingIncomeType: null
        }}
      >
        <Form.Item
          label={
            <VideoLabel
              label='What type of income will the qualifying asset be reducing?'
              videoId='9'
            />
          }
          name='reducingIncomeType'
        >
          <Select placeholder='Please select a type of income'>
            {incomeReductionType.map((type) => (
              <Option value={type}>{type}</Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      {reducingIncomeType
        ? (
            reducingIncomeType === 'C-Corporation'
              ? (
                <CCorporationForm />
                )
              : (
                <NonCCorporationForm
                  reducingIncomeType={reducingIncomeType as IncomeReductionType}
                />
                )
          )
        : null}
    </CalculatorShell>
  )
}

export default Section179Deduction

const CCorporationForm = () => {
  const [form] = Form.useForm()
  const filingYear = useWatch<number>('filingYear', form)
  const qualifyingAssets = useWatch<number>('qualifyingAssets', form)
  const financedPercentage = useWatch<number>('financedPercentage', form)
  const [assetsAdjusted, setAssetsAdjusted] = useState(0)
  const currentYearCashFlow =
    -1 * ((1 - financedPercentage / 100) * assetsAdjusted) || 0
  const currentYearTaxFlow = 0.21 * assetsAdjusted
  const adjustedQualifyingAssets = getAdjustedQualifyingAssets(
    filingYear,
    qualifyingAssets
  )

  useEffect(() => {
    if (filingYear) {
      setAssetsAdjusted(
        getAdjustedQualifyingAssets(filingYear, qualifyingAssets)
      )
    }
  }, [filingYear, qualifyingAssets])

  return (
    <>
      <Form
        form={form}
        layout='vertical'
        initialValues={{
          filingYear: CURRENT_YEAR,
          qualifyingAssets: 0,
          financedPercentage: 90
        }}
      >
        <Form.Item label='Filing year:' name='filingYear'>
          <Select placeholder='Select a filing year'>
            {[2021, 2022].map((year, index) => (
              <Option key={index} value={year}>
                {year}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={`Amount of qualifying assets that will be placed into service in ${filingYear}:`}
          name='qualifyingAssets'
        >
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        {adjustedQualifyingAssets !== qualifyingAssets
          ? (
            <small
              style={{
                marginTop: -10,
                marginBottom: 6,
                display: 'block',
                fontStyle: 'italic'
              }}
            >
              Amount of qualifying assets will be adjusted to{' '}
              {currency.format(adjustedQualifyingAssets)}
            </small>
            )
          : null}
        <Form.Item
          label='Percent of qualifying assets that will be financed:'
          name='financedPercentage'
        >
          <InputNumber
            min={0}
            max={100}
            step={1}
            formatter={percentageFormatter}
          />
        </Form.Item>
      </Form>
      <CalculatorResults
        results={[
          {
            title: 'Current Year Cash Flow',
            currencyResult: currentYearCashFlow
          },
          {
            title: 'Current Year Tax Flow',
            currencyResult: currentYearTaxFlow
          },
          {
            title: 'Expected Current Year Net Tax Flow',
            currencyResult: currentYearCashFlow + currentYearTaxFlow,
            boldTitle: true
          }
        ]}
      >
        <CalculatorNotes
          notes={[
            `Calculations assume entire Section 179 deduction allowed and asset placed into service in ${filingYear}.`,
            'Does not factor in future depreciation recapture nor future note payment'
          ]}
        />
      </CalculatorResults>
    </>
  )
}

const NonCCorporationForm: React.FC<{
  reducingIncomeType: IncomeReductionType
}> = ({ reducingIncomeType }) => {
  const [form] = Form.useForm()
  const filingYear = useWatch('filingYear', form)
  const qualifyingAssets = useWatch<number>('qualifyingAssets', form)
  const financedPercentage = useWatch<number>('financedPercentage', form)
  const currentYearCashFlow =
    -1 * ((1 - financedPercentage / 100) * qualifyingAssets) || 0
  const offsetIncomeType = useWatch('offsetIncomeType', form)
  const [modalVisible, setModalVisible] = useState(false)
  const [preTax, setPreTax] = useState(0)
  const [postTax, setPostTax] = useState(0)
  const [incomeTypes, setIncomeTypes] = useState<TOffsetIncomeType[]>([])
  const [postEngine, setPostEngine] = useState<FederalTaxEngine>(
    new FederalTaxEngine()
  )
  const adjustedQualifyingAssets = getAdjustedQualifyingAssets(
    filingYear,
    qualifyingAssets
  )

  useEffect(() => {
    form.setFieldsValue({ offsetIncomeType: null })
  }, [reducingIncomeType])

  useEffect(() => {
    if (!modalVisible) {
      const incomeTypes = getOffsetIncomeTypes(filingYear).filter((type) => {
        if (reducingIncomeType === 'Self-employment Income') {
          return [
            'selfEmploymentIncome',
            'spouseSelfEmploymentIncome'
          ].includes(type.name)
            ? type
            : null
        } else {
          return type.name === 'investmentMagi' ? type : null
        }
      })

      if (incomeTypes.length === 1) {
        form.setFieldsValue({ offsetIncomeType: incomeTypes[0].name })
      }

      setIncomeTypes(incomeTypes)
    }
  }, [filingYear, modalVisible, offsetIncomeType, reducingIncomeType])

  useEffect(() => {
    let engine = getSavedFederalTaxEngine(filingYear)
    setPreTax(engine.results.estimatedTotalTaxes)
    if (offsetIncomeType) {
      engine = applyOffsettingIncome(
        engine,
        offsetIncomeType,
        adjustedQualifyingAssets,
        true
      )
      engine.calculate()
      setPostTax(engine.results.estimatedTotalTaxes)
      setPostEngine(engine)
    }
  }, [qualifyingAssets, modalVisible, filingYear, offsetIncomeType])

  return (
    <>
      <Form
        form={form}
        layout='vertical'
        initialValues={{
          filingYear: CURRENT_YEAR,
          qualifyingAssets: 0,
          financedPercentage: 90,
          offsetIncomeType: null
        }}
      >
        <Form.Item label='Filing year:' name='filingYear'>
          <Select placeholder='Select a filing year'>
            {[2021, 2022].map((year, index) => (
              <Option key={index} value={year}>
                {year}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <FederalTaxEngineEditor
            type='link'
            filingYear={filingYear}
            onModalViewChange={(visible) => setModalVisible(visible)}
          />
        </Form.Item>
        <Form.Item
          label={`${filingYear} pre-Section 179 deduction federal tax:`}
        >
          <InputNumber
            disabled
            value={preTax}
            formatter={dollarFormatter}
            parser={parser}
          />
        </Form.Item>
        <Form.Item
          label={`Amount of qualifying assets that will be placed into service in ${filingYear}:`}
          name='qualifyingAssets'
        >
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        {adjustedQualifyingAssets !== qualifyingAssets
          ? (
            <small
              style={{
                marginTop: -10,
                marginBottom: 6,
                display: 'block',
                fontStyle: 'italic'
              }}
            >
              Amount of qualifying assets will be adjusted to{' '}
              {currency.format(adjustedQualifyingAssets)}
            </small>
            )
          : null}
        <Form.Item
          label='Percent of qualifying assets that will be financed:'
          name='financedPercentage'
        >
          <InputNumber
            min={0}
            max={100}
            step={0.25}
            formatter={percentageFormatter}
          />
        </Form.Item>
        <Form.Item
          label='What type of income will the qualifying asset be reducing?'
          name='offsetIncomeType'
        >
          <Select
            placeholder='Select an income type:'
            disabled={incomeTypes.length === 1}
          >
            {incomeTypes.map((type, index) => (
              <Option key={index} value={type.name}>
                {type.description}: {currency.format(type.amount)}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={`${filingYear} post-Section 179 deduction federal tax:`}
        >
          <InputNumber
            disabled
            value={postTax}
            formatter={dollarFormatter}
            parser={parser}
          />
        </Form.Item>
      </Form>
      <CalculatorResults
        results={[
          {
            title: 'Current Year Cash Flow',
            currencyResult: currentYearCashFlow
          },
          {
            title: 'Current Year Tax Flow',
            currencyResult: preTax - postTax
          },
          {
            title: 'Expected Current Year Net Tax Flow',
            currencyResult: preTax - postTax + currentYearCashFlow,
            boldTitle: true
          }
        ]}
      >
        <CalculatorNotes
          notes={[
            `Calculations assume entire Section 179 deduction allowed and asset placed into service in ${filingYear}.`,
            'Does not factor in future depreciation recapture nor future note payment'
          ]}
        />
        <FteComparison filingYear={filingYear} results={postEngine} />
      </CalculatorResults>
    </>
  )
}
