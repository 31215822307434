import single from './single'
import headOfHouseholdQualifiedWidow from './head-of-household-qualified-widow'
import marriedFilingJointly from './married-filing-jointly'
import marriedFilingSeperately from './married-filing-seperately'
import { TFilingStatusCode } from '../filing-status'

const brackets = [single, headOfHouseholdQualifiedWidow, marriedFilingSeperately, marriedFilingJointly]

export const getBracketYear = (statusCode: TFilingStatusCode, year: number) => {
  const category = brackets.find(b => b.filingStatusCodes.includes(statusCode))
  const index = (category != null)
    ? category.years.findIndex(
      (bracketYear) => bracketYear.year === year
    )
    : -1
  return (category != null) && index > -1
    ? category.years[index]
    : { year, tiers: [], standardDeduction: 0 }
}

export default brackets
