import { getBracketYear } from '../../../constants/federal-tax-tables'
import { statuses } from '../../../constants/filing-status'
import { currency } from '../../../utils/currency'

const TaxBracketsData: React.FC<{ selectedYear: number }> = ({
  selectedYear
}) => {
  const bracketYearTiers = statuses.map((status) => ({
    status,
    data: getBracketYear(status.code, selectedYear)
  }))

  if (bracketYearTiers.every((t) => t.data.tiers.length === 0)) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <h3>No data found for {selectedYear}</h3>
      </div>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        gap: 24,
        justifyContent: 'center'
      }}
    >
      {bracketYearTiers.map(({ status, data }) => {
        return (
          <div key={status.code} style={{ whiteSpace: 'nowrap' }}>
            <span>
              <table border={1}>
                <thead style={{ backgroundColor: '#d3d3d3' }}>
                  <tr>
                    <th colSpan={3} style={{ padding: 6 }}>
                      {status.label}
                    </th>
                  </tr>
                  <tr>
                    <th style={{ padding: 6 }}>Rate</th>
                    <th style={{ padding: 6 }}>Range</th>
                    <th style={{ padding: 6 }}>Base Tax</th>
                  </tr>
                </thead>
                {data.tiers.map((t) => (
                  <tr>
                    <td
                      style={{
                        textAlign: 'right',
                        paddingLeft: 10,
                        paddingRight: 10
                      }}
                    >
                      {t.rate * 100}%
                    </td>
                    <td
                      style={{
                        textAlign: 'right',
                        paddingLeft: 10,
                        paddingRight: 10
                      }}
                    >
                      ${t.min.toLocaleString()}
                      {t.max === Infinity
                        ? ' or more'
                        : ' to $' + t.max.toLocaleString()}
                    </td>
                    <td
                      style={{
                        textAlign: 'right',
                        paddingLeft: 10,
                        paddingRight: 10
                      }}
                    >
                      {currency.format(t.fixedTaxAmount)}
                    </td>
                  </tr>
                ))}
              </table>
            </span>
          </div>
        )
      })}
    </div>
  )
}

export default TaxBracketsData
