import FederalTaxEngine from '../../../types/federal-tax-engine'

const offsetMedicareWagesAndTips = (
  engine: FederalTaxEngine,
  amount: number
) => {
  const { medicareWagesAndTips, spouseMedicareWagesAndTips, filingStatusCode } =
    engine

  engine.federalTaxableIncome = Math.max(
    0,
    engine.federalTaxableIncome - amount
  )

  engine.magi = Math.max(
    0,
    engine.magi - amount
  )

  if (filingStatusCode === 's') {
    engine.medicareWagesAndTips = Math.max(
      0,
      engine.medicareWagesAndTips - amount
    )
    return engine
  } else {
    if (amount > medicareWagesAndTips) {
      engine.medicareWagesAndTips = 0
      amount -= medicareWagesAndTips
      engine.spouseMedicareWagesAndTips = Math.max(
        0,
        spouseMedicareWagesAndTips - amount
      )
      return engine
    }

    engine.medicareWagesAndTips = medicareWagesAndTips - amount
    return engine
  }
}

export default offsetMedicareWagesAndTips
