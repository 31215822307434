import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  padding-top: 30px;
  margin-top: -10px;
  color: #fff;
  font-size: 12px;
  white-space: nowrap;
`

const Option = styled.div`
  display: inline;
  cursor: pointer;
  color: #d3d3d3;

  small {
    transition: all 300ms;
  }

  &:hover > small {
    font-weight: bold;
    color: white;
  }
`

const openTab = (route?: string) => {
  window.open(`https://www.teachingtaxflow.com/${route}`, '_blank')
}

const Footer = () => {
  return (
    <Container>
      <div>
        <Option onClick={() => openTab('')}>
          <small>© {new Date().getFullYear()} Teaching Tax Flow LLC</small>
        </Option>
        <small style={{ marginLeft: 8, marginRight: 8 }}>|</small>
        <Option onClick={() => openTab('privacy-policy')}>
          <small>Privacy Policy</small>
        </Option>
        <small style={{ marginLeft: 8, marginRight: 8 }}>|</small>
        <Option onClick={() => openTab('terms-of-use')}>
          <small>Terms of Use</small>
        </Option>
      </div>
    </Container>
  )
}

export default Footer
