import FederalTaxEngine from '../../../types/federal-tax-engine'
import getSavedFederalTaxEngine from '../../../utils/get-saved-federal-tax-engine'

type TOffsetIncomeName =
  | 'selfEmploymentIncome'
  | 'spouseSelfEmploymentIncome'
  | 'investmentMagi'

export interface TOffsetIncomeType {
  amount: number
  name: TOffsetIncomeName
  description: string
}

const getOffsetIncomeTypes = (filingYear: number) => {
  const engine = getSavedFederalTaxEngine(filingYear)

  const offsetIncomeTypes: TOffsetIncomeType[] = []

  offsetIncomeTypes.push({
    name: 'selfEmploymentIncome',
    amount: engine.selfEmployment ? engine.selfEmploymentIncome : 0,
    description: 'Self-employment income'
  })

  if (engine.filingStatusCode === 'mfj') {
    offsetIncomeTypes.push({
      name: 'spouseSelfEmploymentIncome',
      amount: engine.spouseSelfEmployment
        ? engine.spouseSelfEmploymentIncome
        : 0,
      description: "Spouse's self-employment income"
    })
  }

  offsetIncomeTypes.push({
    name: 'investmentMagi',
    amount: engine.investmentMagi,
    description: 'Investment income portion of AGI'
  })

  return offsetIncomeTypes
}

const applyOffsettingIncome = (
  engine: FederalTaxEngine,
  offsetIncomeType: TOffsetIncomeName,
  offsetAmount: number,
  applyToFederalTaxableIncome?: boolean
) => {
  switch (offsetIncomeType) {
    case 'selfEmploymentIncome':
      engine.selfEmploymentIncome = Math.max(
        0,
        (engine.selfEmploymentIncome += -1 * offsetAmount)
      )
      break
    case 'spouseSelfEmploymentIncome':
      engine.spouseSelfEmploymentIncome = Math.max(
        0,
        (engine.spouseSelfEmploymentIncome += -1 * offsetAmount)
      )
      break
    case 'investmentMagi':
      engine.investmentMagi = Math.max(
        0,
        (engine.investmentMagi += -1 * offsetAmount)
      )
      break
    default:
      break
  }

  if (applyToFederalTaxableIncome) {
    engine.federalTaxableIncome = Math.max(
      0,
      (engine.federalTaxableIncome += -1 * offsetAmount)
    )
  }

  return engine
}

export { getOffsetIncomeTypes, applyOffsettingIncome }
