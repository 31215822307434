import VideoModal from '../../pages/video/VideoModal'

interface Props {
  label: string
  videoId: string
}

const VideoLabel: React.FC<Props> = ({ label, videoId }) => {
  return (
    <div
      style={{ display: 'flex', alignContent: 'center', alignItems: 'center' }}
    >
      <span style={{ display: 'block', marginRight: 6 }}>{label}:</span>
      <VideoModal id={videoId} />
    </div>
  )
}

export default VideoLabel
