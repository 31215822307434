import { Form, InputNumber, Radio, Select } from 'antd'
import { useWatch } from 'antd/lib/form/Form'
import { useEffect, useState } from 'react'
import FteComparison from '../shared/fte-comparison'
import { CURRENT_YEAR } from '../../constants/default-values'
import { TFilingStatusCode } from '../../constants/filing-status'
import { dollarFormatter, parser } from '../../constants/input-number-formats'
import FederalTaxEngine from '../../types/federal-tax-engine'
import { currency } from '../../utils/currency'
import getSavedFederalTaxEngine from '../../utils/get-saved-federal-tax-engine'
import FederalTaxEngineEditor from '../shared/federal-tax-engine-editor'
import CalculatorNotes from '../shared/notes'
import CalculatorResults from '../shared/results'
import CalculatorShell from '../shared/shell'
import {
  applyOffsettingIncome,
  getOffsetIncomeTypes,
  TOffsetIncomeType
} from '../shared/utils/offset-income-types'
const { Option } = Select

const Election10TCalculator = () => {
  const [form] = Form.useForm()
  const filingYear = useWatch('filingYear', form)
  const offsetIncomeType = useWatch('offsetIncomeType', form)
  const mortgageInterestAmount = useWatch('mortgageInterestAmount', form)
  const deductionsItemized = useWatch('deductionsItemized', form)
  const [currentYearPost, setCurrentYearPost] = useState(0)
  const scheduleAItemizedDeductions = useWatch(
    'scheduleAItemizedDeductions',
    form
  )
  const scheduleAMortgageInterestDeductions = useWatch(
    'scheduleAMortgageInterestDeductions',
    form
  )
  const [filingStatusCode, setFilingStatusCode] =
    useState<TFilingStatusCode>('s')
  const [incomeTypes, setIncomeTypes] = useState<TOffsetIncomeType[]>([])
  const [modalVisible, setModalVisible] = useState(false)
  const [preTax, setPreTax] = useState(0)
  const [postTax, setPostTax] = useState(0)
  const [standardDeduction, setStandardDeduction] = useState(0)
  const [postEngine, setPostEngine] = useState<FederalTaxEngine>(
    new FederalTaxEngine()
  )

  useEffect(() => {
    let engine = getSavedFederalTaxEngine(filingYear)

    if (filingStatusCode !== 'mfj') {
      if (offsetIncomeType === 'spouseSelfEmploymentIncome') {
        form.setFieldsValue({ offsetIncomeType: null })
      }
    }

    setPreTax(engine.results.estimatedTotalTaxes)

    const offsetIncomeTypes = getOffsetIncomeTypes(filingYear)

    setIncomeTypes(offsetIncomeTypes)

    if (offsetIncomeTypes.length === 0) {
      form.setFieldsValue({ offsetIncomeType: null })
    }

    engine = applyOffsettingIncome(
      engine,
      offsetIncomeType,
      mortgageInterestAmount
    )

    if (deductionsItemized) {
      const currentYearPost = Math.max(
        standardDeduction,
        scheduleAItemizedDeductions - mortgageInterestAmount
      )

      setCurrentYearPost(currentYearPost)

      // if (currentYearPost < standardDeduction) {
      engine.federalTaxableIncome += -1 * currentYearPost
      // }
    } else {
      engine.federalTaxableIncome += -1 * (mortgageInterestAmount || 0)
    }
    engine.calculate()

    setStandardDeduction(engine.results.standardDeduction)
    setPostTax(engine.results.estimatedTotalTaxes)
    setPostEngine(engine)
  }, [
    filingStatusCode,
    modalVisible,
    offsetIncomeType,
    mortgageInterestAmount,
    scheduleAItemizedDeductions,
    scheduleAMortgageInterestDeductions,
    deductionsItemized,
    filingYear,
    form,
    standardDeduction
  ])

  return (
    <CalculatorShell title='10-T Election' usesFte>
      <Form
        form={form}
        layout='vertical'
        initialValues={{
          filingYear: CURRENT_YEAR,
          deductionsItemized: false,
          mortgageInterestAmount: 0,
          offsetIncomeType: null,
          scheduleAMortgageInterestDeductions: 0,
          scheduleAItemizedDeductions: 0
        }}
      >
        <Form.Item label='Filing Year' name='filingYear'>
          <Select>
            {[2021, 2022].map((year, index) => (
              <Option key={index} value={year}>
                {year}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <FederalTaxEngineEditor
            type='link'
            filingYear={filingYear}
            onFilingStatusChange={(filingStatusCode) =>
              setFilingStatusCode(filingStatusCode)}
            onModalViewChange={(visible) => setModalVisible(visible)}
          />
        </Form.Item>
        <Form.Item
          label='Do you itemize your deductions?'
          name='deductionsItemized'
        >
          <Radio.Group>
            <Radio value>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label='What type of income will you be offsetting using the 10-T mortgage interest election?'
          name='offsetIncomeType'
        >
          <Select placeholder='Select an income type'>
            {incomeTypes.map((type, index) => (
              <Option key={index} value={type.name}>
                {type.description}: {currency.format(type.amount)}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='Enter the amount of mortgage interest that will used for the 10-T election.'
          name='mortgageInterestAmount'
        >
          <InputNumber
            disabled={!offsetIncomeType}
            min={0}
            formatter={dollarFormatter}
            parser={parser}
          />
        </Form.Item>
        <Form.Item label='Current Year pre 10-T Election Federal Tax'>
          <InputNumber
            disabled
            value={preTax}
            formatter={dollarFormatter}
            parser={parser}
          />
        </Form.Item>
        {deductionsItemized
          ? (
            <>
              <Form.Item
                label='Total Itemized Deductions from Schedule A'
                name='scheduleAItemizedDeductions'
              >
                <InputNumber
                  disabled={!offsetIncomeType}
                  min={0}
                  formatter={dollarFormatter}
                  parser={parser}
                />
              </Form.Item>
              <Form.Item
                label='Total Mortgage Interest Deduction on Schedule A'
                name='scheduleAMortgageInterestDeductions'
              >
                <InputNumber
                  disabled={!offsetIncomeType}
                  min={0}
                  max={scheduleAItemizedDeductions}
                  formatter={dollarFormatter}
                  parser={parser}
                />
              </Form.Item>
              <Form.Item label='Standard Deduction'>
                <InputNumber
                  disabled
                  value={standardDeduction}
                  formatter={dollarFormatter}
                  parser={parser}
                />
              </Form.Item>
              <Form.Item label='Itemized Deductions Above Standard Deduction'>
                <InputNumber
                  disabled
                  value={scheduleAMortgageInterestDeductions - standardDeduction}
                  formatter={dollarFormatter}
                  parser={parser}
                />
              </Form.Item>
              <Form.Item label='Total Deductible Mortgage Interest on Schedule A Above Standard Deduction'>
                <InputNumber
                  disabled
                  value={Math.min(
                    scheduleAMortgageInterestDeductions,
                    scheduleAItemizedDeductions - mortgageInterestAmount
                  )}
                  formatter={dollarFormatter}
                  parser={parser}
                />
              </Form.Item>
              <Form.Item label='Current Year post 10-T Election Itemized Deductions or Standard Deduction'>
                <InputNumber
                  disabled
                  value={currentYearPost}
                  formatter={dollarFormatter}
                  parser={parser}
                />
              </Form.Item>
              <Form.Item label='Current Year post 10-T Reduction of Federal Taxable Income'>
                <InputNumber
                  disabled
                  value={
                  currentYearPost === standardDeduction
                    ? mortgageInterestAmount -
                      (scheduleAItemizedDeductions - standardDeduction)
                    : 0
                }
                  formatter={dollarFormatter}
                  parser={parser}
                />
              </Form.Item>
              <Form.Item label='Current Year post 10-T Election Federal Tax'>
                <InputNumber
                  disabled
                  value={postTax}
                  formatter={dollarFormatter}
                  parser={parser}
                />
              </Form.Item>
            </>
            )
          : (
            <Form.Item label='Current Year post 10-T Election Federal Tax'>
              <InputNumber
                disabled
                value={postTax}
                formatter={dollarFormatter}
                parser={parser}
              />
            </Form.Item>
            )}
      </Form>
      <CalculatorResults
        results={[
          {
            title: 'Cash Flow',
            content: currency.format(0)
          },
          {
            title: 'Current Year Tax Flow',
            content: currency.format(preTax - postTax)
          },
          {
            title: 'Expected Net Tax Flow',
            content: currency.format(preTax - postTax),
            boldTitle: true
          }
        ]}
      >
        <CalculatorNotes
          notes={[
            'Calculations do not factor in deductions for QBI, excess mortgage interest nor alternative minimum tax.',
            'Calculation assumes 10-T is interest is deductible against self-employment or passive income.'
          ]}
        />
        <FteComparison filingYear={filingYear} results={postEngine} />
      </CalculatorResults>
    </CalculatorShell>
  )
}

export default Election10TCalculator
