import { getBracketYear } from '../../../constants/roth-ira-contribution-limits'
import { statuses } from '../../../constants/filing-status'

const RothContributionLimitsData: React.FC<{ selectedYear: number }> = ({
  selectedYear
}) => {
  const bracketYearTiers = statuses
    .filter((s) => ['s', 'mfj', 'mfs'].includes(s.code))
    .map((status) => ({
      status,
      data: getBracketYear(status.code, selectedYear)
    }))

  if (bracketYearTiers.every((t) => t.data.brackets.length === 0)) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <h3>No data found for {selectedYear}</h3>
      </div>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        gap: 24,
        justifyContent: 'center'
      }}
    >
      {bracketYearTiers.map(({ status, data }) => {
        return (
          <div key={status.code} style={{ flex: 1 }}>
            <span>
              <table border={1} style={{ whiteSpace: 'nowrap' }}>
                <thead style={{ backgroundColor: '#d3d3d3' }}>
                  <tr>
                    <th colSpan={3} style={{ padding: 6 }}>
                      {status.label}
                    </th>
                  </tr>
                  <tr>
                    <th>MAGI</th>
                    <th style={{ padding: 6 }}>
                      Max contributions
                      <br /> under age 50
                    </th>
                    <th style={{ padding: 6 }}>
                      Max contributions
                      <br /> over age 50
                    </th>
                  </tr>
                </thead>
                {data.brackets.map((t, index) => (
                  <tr>
                    <td
                      style={{
                        textAlign: 'right',
                        paddingLeft: 10,
                        paddingRight: 10
                      }}
                    >
                      {index === 0 && t.magi > 0 ? 'under ' : null}$
                      {t.magi.toLocaleString()}
                      {index === data.brackets.length - 1 ? ' & over' : null}
                    </td>
                    <td
                      style={{
                        textAlign: 'right',
                        paddingLeft: 10,
                        paddingRight: 10
                      }}
                    >
                      ${t.maxUnder50.toLocaleString()}
                    </td>
                    <td
                      style={{
                        textAlign: 'right',
                        paddingLeft: 10,
                        paddingRight: 10
                      }}
                    >
                      ${t.max50AndOver.toLocaleString()}
                    </td>
                  </tr>
                ))}
              </table>
            </span>
          </div>
        )
      })}
    </div>
  )
}

export default RothContributionLimitsData
