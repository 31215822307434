import { Divider, Typography } from 'antd'
import { BsArrowLeft } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import useMediaQuery from 'use-media-antd-query'
import inIframe from '../../utils/in-iframe'
import Logo from '../logos'

const LogoContainer = styled.div<{ marginBottom: number }>`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
`

const HomeLink = () => (
  <Link to='/'>
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}>
      <BsArrowLeft />
      <span style={{ marginLeft: 3 }}>Home</span>
    </div>
  </Link>
)

const SmartTitle: React.FC<{
  title: string
  titleSmall?: string
  hideHomeLink?: boolean
}> = ({ title, titleSmall, hideHomeLink }) => {
  const colSize = useMediaQuery()

  return (
    <>
      <>{hideHomeLink || inIframe() ? null : <HomeLink />}</>
      <LogoContainer marginBottom={['xs', 'sm'].includes(colSize) ? 12 : 0}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'baseline'
            }}
          >
            <Typography.Title level={4} style={{ textAlign: 'center' }}>
              {['xs', 'sm'].includes(colSize) ? titleSmall || title : title}
            </Typography.Title>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Logo />
        </div>
      </LogoContainer>
      <Divider />
    </>
  )
}

export default SmartTitle
