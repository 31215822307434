import { Layout } from "antd";
import "antd/dist/antd.css";
import "antd-button-color/dist/css/style.css";
import "./index.css";
import FteDataYearProvider from "./contexts/federal-tax-engine-data/federal-tax-engine-data";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Footer from "./components/footer";
import Data from "./pages/data";
import RestrictedArea from "./pages/restricted-area";
import VideoViewer from "./pages/video/VideoViewer";
import { calculators } from "./calculator-metadata";
import Home from "./pages/home";
import "iframe-resizer/js/iframeResizer.contentWindow.min.js";
import inIframe from "./utils/in-iframe";
import { isMobile } from "react-device-detect";
import { MdMobileFriendly } from "react-icons/md";

const { Content } = Layout;

function App() {
  if (isMobile && inIframe()) {
    return (
      <a
        href={window.location.href}
        target="_blank"
        rel="noreferrer"
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",
          fontWeight: "bold",
          fontSize: 18,
          padding: 12,
          minWidth: 200,
        }}
      >
        <span>Click here for mobile-friendly calculator</span>
        <MdMobileFriendly
          style={{
            fontSize: 24,
            marginLeft: 6,
          }}
        />
      </a>
    );
  }

  return (
    <BrowserRouter>
      <Layout style={{ minHeight: inIframe() ? "default" : "100vh" }}>
        <Content
          style={{
            padding: 4,
            paddingBottom: 0,
          }}
        >
          <FteDataYearProvider>
            {/* <Analytics /> */}
            <Routes>
              {calculators
                .filter((c) => !c.disabled)
                .map(({ path, component }, index) => (
                  <Route key={index} path={path} element={component} />
                ))}
              <Route path="tutorials/:code" element={<VideoViewer />} />
              <Route path="data" element={<Data />} />
              <Route path="restricted" element={<RestrictedArea />} />
              <Route path="/" element={<Navigate to="home" />} />
              <Route path="*" element={<Home />} />
            </Routes>
          </FteDataYearProvider>
        </Content>
        <Footer />
      </Layout>
    </BrowserRouter>
  );
}
export default App;
