import styled from 'styled-components'
import useMediaQuery from 'use-media-antd-query'
interface LogoProps {
  height: number | string
  width: number | string
}

const SmallLogo = styled.img<LogoProps>`
  height: ${({ height }) =>
    typeof height === 'string' ? 'auto' : height + 'px'};
  width: ${({ width }) =>
    typeof width === 'string' ? 'auto' : width + 'px'};
`

const LargeLogo = styled.img<LogoProps>`
  height: ${({ height }) =>
    typeof height === 'string' ? 'auto' : height + 'px'};
    width: ${({ width }) =>
    typeof width === 'string' ? 'auto' : width + 'px'};
`

const Logo: React.FC<{ small?: LogoProps, large?: LogoProps }> = ({
  small,
  large
}) => {
  const colSize = useMediaQuery()

  return (
    <>
      {['xs', 'sm'].includes(colSize)
        ? (
          <SmallLogo
            height={small?.height ? small.height : 50}
            width={small?.width ? small.width : 'auto'}
            src='./teaching-tax-flow-small.png'
            alt=''
          />
          )
        : (
          <LargeLogo
            height={large?.height ? large.height : 'auto'}
            width={large?.width ? large.width : 150}
            src='./teaching-tax-flow-large.png'
            alt=''
          />
          )}
    </>
  )
}

export default Logo
