import CalculatorShell from '../shared/shell'
import HsaImmediateTaxDeductionCalculator from './immediate-tax-deduction'

const HsaContribution = () => {
  return (
    <CalculatorShell
      title='Health Savings Account Contribution'
      titleSmall='HSA Contribution'
    >
      <HsaImmediateTaxDeductionCalculator />
    </CalculatorShell>
  )
}

export default HsaContribution
