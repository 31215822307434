import { Card, Divider } from 'antd'
import { PropsWithChildren } from 'react'
import CookiesDisabled from '../../../components/cookies-disabled'
import SmartTitle from '../../../components/smart-title'
import useMediaQuery from 'use-media-antd-query'

type Props = PropsWithChildren<{
  title: string
  titleSmall?: string
  hideHomeLink?: boolean
  usesFte?: boolean
}>

const CalculatorShell: React.FC<Props> = ({
  title,
  titleSmall,
  hideHomeLink,
  usesFte,
  children
}) => {
  const colSize = useMediaQuery()
  if (usesFte && !navigator.cookieEnabled) {
    return <CookiesDisabled colSize={colSize} title={title} />
  }
  return (
    <Card style={{ border: hideHomeLink ? 'none' : 'inherit' }}>
      <SmartTitle
        title={title}
        titleSmall={titleSmall}
        hideHomeLink={hideHomeLink}
      />
      {children}
      <Divider style={{ margin: '25px 0' }} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            maxWidth: 600,
            whiteSpace: 'break-spaces',
            textAlign: 'center',
            width: '75%',
            paddingBottom: 12,
            fontSize: 8
          }}
        >
          Calculations do not factor in changes to Qualified Business Income
          or Alternative Minimum Tax. These calculations are for estimation
          and informational purposes only, and are not intended to provide,
          and should not be relied on for, tax, legal or accounting advice.
        </div>
      </div>
    </Card>
  )
}

export default CalculatorShell
