enum HsaPlanType {
  'individual' = 'Individual',
  'family' = 'Family',
}

// const If Individual maximum allowed is $3,650 but if "yes" on Age 55 or older $4,650;
// If Family plan maximum allowed is $7,300 but if "yes" on Age 55 or older $8,300

const getMaximumAllowedContributions = (
  planType: HsaPlanType,
  aged55: boolean
) => {
  switch (planType) {
    case HsaPlanType.individual:
      return aged55 ? 4650 : 3650
    case HsaPlanType.family:
      return aged55 ? 8300 : 7300
    default:
      return 0
  }
}
export { HsaPlanType, getMaximumAllowedContributions }
