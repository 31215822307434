import { Form, InputNumber, Radio, Select } from 'antd'
import { useWatch } from 'antd/lib/form/Form'
import { useEffect, useState } from 'react'
import { CURRENT_YEAR } from '../../constants/default-values'
import { statuses } from '../../constants/filing-status'
import {
  dollarFormatter,
  parser,
  percentageFormatter
} from '../../constants/input-number-formats'
import { calculateContributionLimit } from '../../constants/roth-ira-contribution-limits'
import { currency } from '../../utils/currency'
import CalculatorNotes from '../shared/notes'
import CalculatorResults from '../shared/results'
import CalculatorShell from '../shared/shell'
const { Option } = Select

const RothContribution = () => {
  const [form] = Form.useForm()
  const filingYear = useWatch('filingYear', form)
  const filingStatusCode = useWatch('filingStatusCode', form)
  const magi = useWatch('magi', form)
  const aged50OrOlder = useWatch('aged50OrOlder', form)
  const rothType = useWatch('rothType', form)
  const estimatedRothContribution = useWatch('estimatedRothContribution', form)
  const expectedAnnualRoi = useWatch('expectedAnnualRoi', form)
  const yearsUntilQualifiedDistributions = useWatch(
    'yearsUntilQualifiedDistributions',
    form
  )
  const [expectedCashFlow, setExpectedFutureCashFlow] = useState<number>(0)
  const [maxRothContribution, setMaxRothContribution] = useState<number>(0)

  useEffect(() => {
    if (magi) {
      let limit = 0
      if (rothType === 'ira') {
        limit =
          calculateContributionLimit(
            filingStatusCode,
            filingYear,
            magi,
            aged50OrOlder
          ) || 0
      } else {
        limit = aged50OrOlder ? 27000 : 20500
      }

      if (estimatedRothContribution > limit) {
        form.setFieldsValue({ estimatedRothContribution: limit })
      }

      setMaxRothContribution(limit)
    }
  }, [
    form,
    filingYear,
    estimatedRothContribution,
    filingStatusCode,
    magi,
    aged50OrOlder,
    rothType
  ])

  useEffect(() => {
    setExpectedFutureCashFlow(
      estimatedRothContribution *
        (1 + expectedAnnualRoi / 100) ** yearsUntilQualifiedDistributions
    )
  }, [
    estimatedRothContribution,
    expectedAnnualRoi,
    yearsUntilQualifiedDistributions
  ])

  return (
    <CalculatorShell title='Roth Contribution'>
      <Form
        form={form}
        layout='vertical'
        initialValues={{
          filingYear: CURRENT_YEAR,
          filingStatusCode: 's',
          magi: 0,
          aged50OrOlder: false,
          rothType: 'ira',
          estimatedRothContribution: 0,
          yearsUntilQualifiedDistributions: 0,
          expectedAnnualRoi: 2.0
        }}
      >
        <Form.Item label='Filing year:' name='filingYear'>
          <Select>
            {[2021, 2022].map((year, index) => (
              <Option key={index} value={year}>
                {year}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label='Filing status:' name='filingStatusCode'>
          <Select>
            {statuses
              .filter((s) => ['s', 'mfj', 'mfs'].includes(s.code))
              .map((status, index) => (
                <Option key={index} value={status.code}>
                  {status.label}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item label='Adjusted gross income (AGI):' name='magi'>
          <InputNumber min={0} formatter={dollarFormatter} parser={parser} />
        </Form.Item>
        <Form.Item
          label='Aged 50 or older at the end of the year?'
          name='aged50OrOlder'
        >
          <Radio.Group>
            <Radio value>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label='Type of Roth account:' name='rothType'>
          <Radio.Group>
            <Radio value='ira'>IRA</Radio>
            <Radio value='esp'>Employer-Sponsored Plan (ESP)</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label='Maximum Roth contribution:'>
          <InputNumber
            min={0}
            formatter={dollarFormatter}
            parser={parser}
            disabled
            value={maxRothContribution}
          />
        </Form.Item>
        <Form.Item
          label={
            <>
              Estimated Roth contribution
              <small style={{ marginLeft: 3 }}>
                (max: ${maxRothContribution.toLocaleString()})
              </small>
              :
            </>
          }
          name='estimatedRothContribution'
        >
          <InputNumber
            min={0}
            max={maxRothContribution}
            formatter={dollarFormatter}
            parser={parser}
          />
        </Form.Item>
        <Form.Item
          label='Years until qualified distributions:'
          name='yearsUntilQualifiedDistributions'
        >
          <InputNumber min={0} step={1} />
        </Form.Item>
        <Form.Item
          label='Expected annual rate of return on investment:'
          name='expectedAnnualRoi'
        >
          <InputNumber
            min={2}
            max={25}
            step={0.01}
            formatter={percentageFormatter}
          />
        </Form.Item>
      </Form>
      <CalculatorResults
        showDivider
        results={[
          {
            title: 'Current Year Cash Flow',
            content:
              estimatedRothContribution > 0
                ? currency.format(-1 * estimatedRothContribution)
                : currency.format(0)
          },
          {
            title: 'Current Year Tax Flow',
            content: currency.format(0)
          },
          {
            title: 'Expected Future Years Cash Flow',
            content: currency.format(expectedCashFlow)
          },
          {
            title: 'Expected Net Tax Flow',
            content: currency.format(
              expectedCashFlow - estimatedRothContribution
            ),
            boldTitle: true
          }
        ]}
      >
        {' '}
        <CalculatorNotes
          notes={[
            'Potential future years tax flow represents tax free growth and penalty free distributions.',
            'Calculator assumes eligible earned income exists for Roth contribution.'
          ]}
        />
      </CalculatorResults>
    </CalculatorShell>
  )
}

export default RothContribution
