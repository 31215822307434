import {
  getYearRates,
  TSelfEmploymentRates
} from '../../../constants/self-employment-rates'

const SelfEmploymentRates: React.FC<{ selectedYear: number }> = ({
  selectedYear
}) => {
  const yearRates = getYearRates(selectedYear)

  if (yearRates == null) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <h3>No data found for {selectedYear}</h3>
      </div>
    )
  }

  const tableMap = new Map([
    ['maxFica', { label: 'Max FICA', type: 'money' }],
    [
      'socialSecurityMultiplier',
      { label: 'Social Security Multiplier', type: 'percentage' }
    ],
    [
      'socialSecurityRate',
      { label: 'Social Security Rate', type: 'percentage' }
    ],
    ['medicareRate', { label: 'Medicare Rate', type: 'percentage' }]
  ])

  return (
    <div>
      <table border={1} style={{ whiteSpace: 'nowrap' }}>
        {Object.keys(yearRates)
          .filter((key) => key !== 'year')
          .map((key) => (
            <tr>
              <td
                style={{
                  paddingLeft: 10,
                  paddingRight: 10
                }}
              >
                {tableMap.get(key)?.label}
              </td>
              <td
                style={{
                  textAlign: 'right',
                  paddingLeft: 10,
                  paddingRight: 10
                }}
              >
                {tableMap.get(key)?.type === 'percentage'
                  ? (
                      yearRates[key as keyof TSelfEmploymentRates] * 100
                    ).toLocaleString() + '%'
                  : '$' +
                    yearRates[
                      key as keyof TSelfEmploymentRates
                    ].toLocaleString()}
              </td>
            </tr>
          ))}
      </table>
    </div>
  )
}

export default SelfEmploymentRates
