import { message, Typography } from 'antd'
import Button from 'antd-button-color'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
const { Paragraph, Text } = Typography

const CookiesDisabled: React.FC<{ colSize: string, title: string }> = ({
  colSize,
  title
}) => {
  const navigate = useNavigate()
  const [cookiesEnabled, setCookiesEnabled] = useState(false)

  const checkCookiesEnabled = () => {
    message.destroy('cookie-error')

    if (!navigator.cookieEnabled) {
      message.open({
        type: 'error',
        content: 'Sorry, cookies have not yet been enabled.',
        key: 'cookie-error',
        duration: 10
      })
    }
    setCookiesEnabled(navigator.cookieEnabled)
    setTimeout(() => {
      navigate(0)
    }, 5000)
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        height: 'calc(100vh - 50px)',
        alignItems: 'center',
        marginTop: ['xs', 'sm'].includes(colSize) ? -12.5 : -25,
        textAlign: 'center'
      }}
    >
      <div style={{ background: 'white', padding: '24px 48px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <Paragraph style={{ marginBottom: 12, marginTop: 24 }}>
            <Text
              strong
              style={{
                fontSize: 24
              }}
            >
              {cookiesEnabled
                ? 'Cookies are now enabled!'
                : "Your browser's cookies appear to be disabled"}
            </Text>
          </Paragraph>
          <Paragraph
            style={{ textAlign: 'center', marginBottom: 60, maxWidth: 400 }}
          >
            <Text
              style={{
                fontSize: 14
              }}
            >
              {cookiesEnabled
                ? (
                    'You will now be redirected to your destination'
                  )
                : (
                  <>
                    The use of the <b>{title}</b> calculator requires{' '}
                    <a
                      href='https://www.wikihow.com/Enable-Cookies-and-JavaScript'
                      target='_blank' rel='noreferrer'
                    >
                      all cookies to be enabled
                    </a>{' '}
                    in your browser.
                  </>
                  )}
            </Text>
          </Paragraph>
          {cookiesEnabled
            ? null
            : (
              <Button
                type='primary'
                disabled={cookiesEnabled}
                onClick={() => checkCookiesEnabled()}
              >
                Click to verify cookies have been enabled
              </Button>
              )}
        </div>
      </div>
    </div>
  )
}

export default CookiesDisabled
