import { ReactElement } from 'react'
import Election10TCalculator from './calculators/10-t-election'
import Plan529Contribution from './calculators/529-plan-contribution'
import AppreciatedNonCashAssetsToCharity from './calculators/appreciated-non-cash-assets-to-charity'
import BonusDepreciationDeduction from './calculators/bonus-depreciation-deduction'
import CostSegregationRealEstate from './calculators/cost-segregation-real-estate'
import DeMinimusSafeHarborElection from './calculators/de-minimus-safe-harbor-election'
import FederalIncomeTaxBalanceDueOrOverpayment from './calculators/federal-income-tax-balance-due-or-overpayment'
import FederalTaxEngine from './calculators/federal-tax-engine'
import HomeOfficeDeduction from './calculators/home-office-deduction'
import HsaContribution from './calculators/hsa'
import MarginalTaxRate from './calculators/marginal-tax-rate'
import PassiveIncomeGeneration from './calculators/passive-income-generation'
import RothContribution from './calculators/roth-contribution'
import RothConversion from './calculators/roth-conversion'
import Section1202QSBS from './calculators/section-1202-qsbs'
import Section125PlanContribution from './calculators/section-125-plan-contribution'
import Section179Deduction from './calculators/section-179-deduction'
import TaxDeferralVsTaxFreeIncome from './calculators/tax-deferral-vs-tax-free-income'

export type TCalculatorStatus =
  | 'Queued'
  | 'Development'
  | 'Review'
  | 'Live'
  | 'Disabled'
  | 'Hold'

interface TCalculatorMetadata {
  name: string
  status: TCalculatorStatus
  path: string
  disabled?: boolean
  component: ReactElement
}

const CalculatorStatusColors = new Map<TCalculatorStatus, string>([
  ['Queued', '#36C5F0'],
  ['Development', '#4A154B'],
  ['Review', '#ECB22E'],
  ['Live', '#2dc937'],
  ['Disabled', '#F0F0F0'],
  ['Hold', '#E01E5A']
])

const calculators: TCalculatorMetadata[] = [
  {
    name: '10-T Election',
    status: 'Hold',
    path: '10-t-election',
    component: <Election10TCalculator />
  },
  {
    name: '529 Plan Contribution',
    status: 'Live',
    path: '529-plan-contribution',
    component: <Plan529Contribution />
  },
  {
    name: 'Bonus Depreciation Deduction',
    status: 'Live',
    path: 'bonus-depreciation-deductionn',
    component: <BonusDepreciationDeduction />
  },
  {
    name: 'Cost Segregation for Real Estate',
    status: 'Live',
    path: 'cost-segregation-real-estate',
    component: <CostSegregationRealEstate />
  },
  {
    name: 'De Minimus Safe Harbor Election',
    status: 'Live',
    path: 'de-minimus-safe-harbor-election',
    component: <DeMinimusSafeHarborElection />
  },
  {
    name: 'Donate Appreciated Non-Cash Assets to Charity',
    status: 'Live',
    path: 'appreciated-non-cash-assets-to-charity',
    component: <AppreciatedNonCashAssetsToCharity />
  },
  {
    name: 'Federal Income Tax Balance Due or (Overpayment)',
    status: 'Live',
    path: 'federal-income-tax-balance-due-or-overpayment',
    component: <FederalIncomeTaxBalanceDueOrOverpayment />
  },
  {
    name: 'Federal Tax Engine',
    status: 'Live',
    path: 'federal-tax-engine',
    component: <FederalTaxEngine />
  },
  {
    name: 'Home Office Deduction',
    status: 'Live',
    path: 'home-office-deduction',
    component: <HomeOfficeDeduction />
  },
  {
    name: 'HSA Contribution',
    status: 'Live',
    path: 'hsa-contribution',
    component: <HsaContribution />
  },
  {
    name: 'Marginal Tax Rate (MTR)',
    status: 'Live',
    path: 'marginal-tax-rate',
    component: <MarginalTaxRate />
  },
  {
    name: 'Passive Income Generation',
    status: 'Live',
    path: 'passive-income-generation',
    component: <PassiveIncomeGeneration />
  },
  {
    name: 'Roth Contribution',
    status: 'Live',
    path: 'roth-contribution',
    component: <RothContribution />
  },
  {
    name: 'Roth Conversion',
    status: 'Live',
    path: 'roth-conversion',
    component: <RothConversion />
  },
  {
    name: 'Section 1202 QSBS',
    status: 'Live',
    path: 'section-1202-qsbs',
    component: <Section1202QSBS />
  },
  {
    name: 'Section 125 Plan Contribution',
    status: 'Live',
    path: 'section-125-plan-contribution',
    component: <Section125PlanContribution />
  },
  {
    name: 'Section 179 Deduction',
    status: 'Live',
    path: 'section-179-deduction',
    component: <Section179Deduction />
  },
  {
    name: 'Tax Deferral vs. Tax Free Income',
    status: 'Live',
    path: 'tax-deferral-vs-tax-free-income',
    component: <TaxDeferralVsTaxFreeIncome />
  }
]

export { calculators, CalculatorStatusColors }
