import { getBaseFederalIncomeTax } from '../constants/base-federal-tax-calculator'
import { CURRENT_YEAR } from '../constants/default-values'
import brackets from '../constants/federal-tax-tables'
import { TFilingStatusCode } from '../constants/filing-status'
import {
  getCapitalGainsAndDividendsBracket,
  TBracketThreshold
} from '../constants/ltcg-qd-brackets'
import { getAdditionalMedicareTax } from '../constants/medicare-tax-triggers'
import { getNetInvestmentIncomeTax } from '../constants/net-investment-income-tax-triggers'
import { getSelfEmploymentTaxes } from '../constants/self-employment-rates'

export interface IFederalTaxEngineResults {
  federalIncomeTaxRate: number
  federalIncomeTaxBase: number
  federalIncomeTaxExplanation: string
  capitalGainsTaxRate: number
  additionalMedicareTax: number
  additionalMedicareTaxExplanation: string
  netInvestmentIncomeTax: number
  netInvestmentIncomeTaxExplanation: string
  capitalGainsAndDividendsTax: number
  capitalGainsAndDividendsTaxExplanation: string
  selfEmployedFicaTax: {
    self: number
    spouse: number
  }
  selfEmployedFicaTaxExplanation: {
    self: string
    spouse: string
  }
  selfEmployedMedicareTax: {
    self: number
    spouse: number
  }
  selfEmployedMedicareTaxExplanation: {
    self: string
    spouse: string
  }
  standardDeduction: number
  estimatedTotalTaxes: number
}

export interface IFederalTaxEngine {
  filingYear: number
  filingStatusCode: TFilingStatusCode
  medicareWagesAndTips: number
  selfEmployment: boolean
  selfEmploymentIncome: number
  spouseMedicareWagesAndTips: number
  spouseSelfEmployment: boolean
  spouseSelfEmploymentIncome: number
  magi: number
  longTermCapitalGains: number
  qualifiedDividends: number
  investmentMagi: number
  federalTaxableIncome: number
  capitalGainsAndDividendsBracket: TBracketThreshold
  results: IFederalTaxEngineResults
}

export enum FederalTaxEngineParameterField {
  filingYear = 'filingYear',
  filingStatusCode = 'filingStatusCode',
  medicareWagesAndTips = 'medicareWagesAndTips',
  selfEmployment = 'selfEmployment',
  selfEmploymentIncome = 'selfEmploymentIncome',
  spouseMedicareWagesAndTips = 'spouseMedicareWagesAndTips',
  spouseSelfEmployment = 'spouseSelfEmployment',
  spouseSelfEmploymentIncome = 'spouseSelfEmploymentIncome',
  magi = 'magi',
  longTermCapitalGains = 'longTermCapitalGains',
  qualifiedDividends = 'qualifiedDividends',
  investmentMagi = 'investmentMagi',
  federalTaxableIncome = 'federalTaxableIncome',
}

class FederalTaxEngine implements IFederalTaxEngine {
  filingYear: number = new Date().getFullYear()
  filingStatusCode: TFilingStatusCode = 's'
  medicareWagesAndTips: number
  selfEmployment: boolean
  selfEmploymentIncome: number
  spouseMedicareWagesAndTips: number
  spouseSelfEmployment: boolean
  spouseSelfEmploymentIncome: number
  magi: number = 0
  longTermCapitalGains: number
  qualifiedDividends: number
  investmentMagi: number
  federalTaxableIncome: number = 0
  capitalGainsAndDividendsBracket = {
    percentage: 0,
    minTaxableIncome: 0,
    maxTaxableIncome: 0
  }

  results: IFederalTaxEngineResults

  constructor (
    filingYear?: number,
    filingStatusCode?: TFilingStatusCode,
    medicareWagesAndTips?: number,
    selfEmployment?: boolean,
    selfEmploymentIncome?: number,
    spouseMedicareWagesAndTips?: number,
    spouseSelfEmployment?: boolean,
    spouseSelfEmploymentIncome?: number,
    magi?: number,
    longTermCapitalGains?: number,
    qualifiedDividends?: number,
    investmentMagi?: number,
    federalTaxableIncome?: number
  ) {
    this.filingYear = filingYear || CURRENT_YEAR
    this.filingStatusCode = filingStatusCode || 's'
    this.medicareWagesAndTips = medicareWagesAndTips || 0
    this.selfEmployment = selfEmployment !== undefined ? selfEmployment : false
    this.selfEmploymentIncome = selfEmploymentIncome || 0
    this.spouseMedicareWagesAndTips = spouseMedicareWagesAndTips || 0
    this.spouseSelfEmployment =
      spouseSelfEmployment !== undefined ? spouseSelfEmployment : false
    this.spouseSelfEmploymentIncome = spouseSelfEmploymentIncome || 0
    this.magi = magi || 0
    this.longTermCapitalGains = longTermCapitalGains || 0
    this.qualifiedDividends = qualifiedDividends || 0
    this.investmentMagi = investmentMagi || 0
    this.federalTaxableIncome = federalTaxableIncome || 0
    this.results = {
      federalIncomeTaxRate: 0,
      federalIncomeTaxBase: 0,
      federalIncomeTaxExplanation: '',
      capitalGainsTaxRate: 0,
      additionalMedicareTax: 0,
      additionalMedicareTaxExplanation: '',
      netInvestmentIncomeTax: 0,
      netInvestmentIncomeTaxExplanation: '',
      capitalGainsAndDividendsTax: 0,
      capitalGainsAndDividendsTaxExplanation: '',
      selfEmployedFicaTax: {
        self: 0,
        spouse: 0
      },
      selfEmployedFicaTaxExplanation: {
        self: '',
        spouse: ''
      },
      selfEmployedMedicareTax: {
        self: 0,
        spouse: 0
      },
      selfEmployedMedicareTaxExplanation: {
        self: '',
        spouse: ''
      },
      estimatedTotalTaxes: 0,
      standardDeduction: 0
    }

    this.calculate()
  }

  calculate () {
    if (this.filingStatusCode === 's') {
      this.spouseMedicareWagesAndTips = 0
      this.spouseSelfEmployment = false
      this.spouseSelfEmploymentIncome = 0
    }

    if (!this.selfEmployment) {
      this.selfEmploymentIncome = 0
    }

    if (!this.spouseSelfEmployment) {
      this.spouseSelfEmploymentIncome = 0
    }

    this._calculateStandardDeduction()
    this._calculateCapitalGainsAndDividendsBracket()
    this._calculateAdditionalMedicareTax()
    this._calculateNetInvestmentIncomeTax()
    this._calculateSelfEmploymentTaxes()
    this._calculateFederalTaxRateAndBase()
    this._calculateCapitalGainsAndDividendsTax()
    this._calculateEstimatedTotalTaxes()
  }

  updateField (field: FederalTaxEngineParameterField, value: any) {
    switch (field) {
      case 'filingYear':
        this.filingYear = parseFloat(value)
        break
      case 'filingStatusCode':
        this.filingStatusCode = value
        break
      case 'medicareWagesAndTips':
        this.medicareWagesAndTips = parseFloat(value) || 0
        break
      case 'selfEmployment':
        this.selfEmployment = value
        break
      case 'selfEmploymentIncome':
        this.selfEmploymentIncome = parseFloat(value) || 0
        break
      case 'spouseMedicareWagesAndTips':
        this.spouseMedicareWagesAndTips = parseFloat(value) || 0
        break
      case 'spouseSelfEmployment':
        this.spouseSelfEmployment = value
        break
      case 'spouseSelfEmploymentIncome':
        this.spouseSelfEmploymentIncome = parseFloat(value) || 0
        break
      case 'magi':
        this.magi = parseFloat(value) || 0
        break
      case 'longTermCapitalGains':
        this.longTermCapitalGains = parseFloat(value) || 0
        break
      case 'qualifiedDividends':
        this.qualifiedDividends = parseFloat(value) || 0
        break
      case 'investmentMagi':
        this.investmentMagi = parseFloat(value) || 0
        break
      case 'federalTaxableIncome':
        this.federalTaxableIncome = parseFloat(value) || 0
        break
      default:
        break
    }

    this.calculate()
  }

  getParemetersAndResults () {
    return {
      'Federal Taxable Income': this.federalTaxableIncome,
      'Federal Income Tax Rate': this.results.federalIncomeTaxRate,
      MAGI: this.magi,
      'Medicare Wages And Tips': this.medicareWagesAndTips,
      'Self Employment Income': this.selfEmploymentIncome,
      'Spouse Medicare Wages And Tips': this.spouseMedicareWagesAndTips,
      'Spouse Self Employment Income': this.spouseSelfEmploymentIncome,
      'Long-term Capital Gains': this.longTermCapitalGains,
      'Qualified Dividends': this.qualifiedDividends,
      'Investment MAGI': this.investmentMagi,
      'Capital Gains Tax Rate': this.results.capitalGainsTaxRate,
      'Additional Medicare Tax': this.results.additionalMedicareTax,
      'Net Investment Income Tax': this.results.netInvestmentIncomeTax,
      'Capital Gains And Dividends Tax':
        this.results.capitalGainsAndDividendsTax,
      'Self-Emplyoment Tax FICA': this.results.selfEmployedFicaTax.self,
      'Spouse Self-Emplyoment Tax FICA':
        this.results.selfEmployedFicaTax.spouse,
      'Self-Emplyoment Tax Medicare': this.results.selfEmployedMedicareTax.self,
      'Spouse Self-Emplyoment Tax Medicare':
        this.results.selfEmployedMedicareTax.spouse,
      'Standard Deduction': this.results.standardDeduction,
      'Estimated Total Taxes': this.results.estimatedTotalTaxes
    }
  }

  private _calculateStandardDeduction () {
    const filingStatus = brackets.find((b) =>
      b.filingStatusCodes.includes(this.filingStatusCode)
    )
    if (filingStatus != null) {
      const bracketYear = filingStatus.years.find(
        (y) => y.year === this.filingYear
      )
      const standardDeduction = (bracketYear != null) ? bracketYear.standardDeduction : 0
      this.results = {
        ...this.results,
        standardDeduction
      }
    }
  }

  private _calculateCapitalGainsAndDividendsBracket () {
    this.capitalGainsAndDividendsBracket = getCapitalGainsAndDividendsBracket(
      this.filingStatusCode,
      this.filingYear,
      this.federalTaxableIncome
    ) as TBracketThreshold
  }

  private _calculateAdditionalMedicareTax () {
    const { amount, explanation } = getAdditionalMedicareTax(
      this.filingStatusCode,
      this.filingYear,
      this.medicareWagesAndTips + this.spouseMedicareWagesAndTips,
      this.selfEmploymentIncome + this.spouseSelfEmploymentIncome
    )

    this.results = {
      ...this.results,
      additionalMedicareTax: amount,
      additionalMedicareTaxExplanation: explanation
    }
  }

  private _calculateNetInvestmentIncomeTax () {
    const { amount, explanation } = getNetInvestmentIncomeTax(
      this.filingStatusCode,
      this.filingYear,
      this.magi,
      this.investmentMagi
    )

    this.results = {
      ...this.results,
      netInvestmentIncomeTax: amount,
      netInvestmentIncomeTaxExplanation: explanation
    }
  }

  private _calculateSelfEmploymentTaxes () {
    if (this.selfEmployment || this.spouseSelfEmployment) {
      const {
        ficaTax,
        ficaTaxExplanation,
        medicareTax,
        medicareTaxExplanation
      } = getSelfEmploymentTaxes(
        this.filingYear,
        this.selfEmploymentIncome,
        this.medicareWagesAndTips
      )

      const {
        ficaTax: spouseFicaTax,
        ficaTaxExplanation: spouseFicaTaxExplanation,
        medicareTax: spouseMedicareTax,
        medicareTaxExplanation: spouseMedicareTaxExplanation
      } = getSelfEmploymentTaxes(
        this.filingYear,
        this.spouseSelfEmploymentIncome,
        this.spouseMedicareWagesAndTips
      )

      this.results = {
        ...this.results,
        selfEmployedFicaTax: {
          self: ficaTax,
          spouse: spouseFicaTax
        },
        selfEmployedFicaTaxExplanation: {
          self: ficaTaxExplanation,
          spouse: spouseFicaTaxExplanation
        },
        selfEmployedMedicareTax: {
          self: medicareTax,
          spouse: spouseMedicareTax
        },
        selfEmployedMedicareTaxExplanation: {
          self: medicareTaxExplanation,
          spouse: spouseMedicareTaxExplanation
        }
      }
    }
  }

  private _calculateFederalTaxRateAndBase () {
    const federalTaxableIncomeLessLtcgAndQd = Math.max(
      this.federalTaxableIncome -
        this.longTermCapitalGains -
        this.qualifiedDividends,
      0
    )
    const { rate, fixedTaxAmount, floorAmount } = getBaseFederalIncomeTax(
      this.filingStatusCode,
      this.filingYear,
      federalTaxableIncomeLessLtcgAndQd
    )

    const federalIncomeTaxBase =
      fixedTaxAmount + (federalTaxableIncomeLessLtcgAndQd - floorAmount) * rate

    this.results = {
      ...this.results,
      federalIncomeTaxRate: rate,
      federalIncomeTaxBase,
      federalIncomeTaxExplanation: `Federal tax estimated at $${fixedTaxAmount.toLocaleString()} plus ${
        rate * 100 + '%'
      } of taxable income amount over $${floorAmount.toLocaleString()}`
    }
  }

  private _calculateCapitalGainsAndDividendsTax () {
    if (this.capitalGainsAndDividendsBracket) {
      const { percentage, minTaxableIncome } =
        this.capitalGainsAndDividendsBracket

      const capitalGainsAndDividendsTax =
        (this.longTermCapitalGains + this.qualifiedDividends) *
        (percentage / 100)

      this.results = {
        ...this.results,
        capitalGainsAndDividendsTax,
        capitalGainsAndDividendsTaxExplanation: `For taxable income amount over $${minTaxableIncome.toLocaleString()}, LTCG and QD are taxed at ${
          percentage + '%'
        } for ${this.filingYear}`,
        capitalGainsTaxRate: percentage / 100
      }
    }
  }

  private _calculateEstimatedTotalTaxes () {
    const {
      federalIncomeTaxBase,
      additionalMedicareTax,
      netInvestmentIncomeTax,
      selfEmployedFicaTax,
      selfEmployedMedicareTax,
      capitalGainsAndDividendsTax
    } = this.results

    this.results.estimatedTotalTaxes =
      federalIncomeTaxBase +
      additionalMedicareTax +
      netInvestmentIncomeTax +
      selfEmployedFicaTax.self +
      selfEmployedFicaTax.spouse +
      selfEmployedMedicareTax.self +
      selfEmployedMedicareTax.spouse +
      capitalGainsAndDividendsTax
  }
}

export default FederalTaxEngine
