export type IncomeReductionType =
  | 'C-Corporation'
  | 'S-Corporation'
  | 'Self-employment Income'
  | 'Passive Income'

const incomeReductionType: IncomeReductionType[] = [
  'C-Corporation',
  'S-Corporation',
  'Self-employment Income',
  'Passive Income'
]

export { incomeReductionType }
