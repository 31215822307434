interface TQualifyingAssetTier {
  minimum: number
  maximum: number
}

interface TQualifyingAssetYear {
  year: number
  tiers: TQualifyingAssetTier[]
}

const years: TQualifyingAssetYear[] = [
  {
    year: 2021,
    tiers: [
      {
        minimum: -Infinity,
        maximum: 1050000
      },
      {
        minimum: 1050001,
        maximum: 2620000
      },
      {
        minimum: 2620001,
        maximum: Infinity
      }
    ]
  },
  {
    year: 2022,
    tiers: [
      {
        minimum: -Infinity,
        maximum: 1080000
      },
      {
        minimum: 1080001,
        maximum: 2700000
      },
      {
        minimum: 2700001,
        maximum: Infinity
      }
    ]
  }
]

const getAdjustedQualifyingAssets = (
  filingYear: number,
  qualifyingAssets: number
) => {
  if (years.findIndex(y => y.year === filingYear) === -1) {
    console.warn(
      `Qualifying asset tiers not found for filing year: ${filingYear}`
    )
    return 0
  }

  const { tiers } = years?.filter((year) => year.year === filingYear)[0]

  let adjusted = 0
  if (qualifyingAssets <= tiers[0].maximum) {
    adjusted = qualifyingAssets
  } else if (qualifyingAssets >= tiers[1].minimum && qualifyingAssets <= tiers[1].maximum) {
    adjusted = tiers[0].maximum
  } else {
    adjusted = tiers[0].maximum - (qualifyingAssets - tiers[2].minimum)
  }

  return Math.max(0, adjusted)
}

export default getAdjustedQualifyingAssets
