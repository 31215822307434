import { Card, Col, Row, Tabs } from 'antd'
import Calculators from '../calculators'
import Data from '../data'
const Home = () => {
  return (
    <Card>
      <Row
        gutter={12}
        align='middle'
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Col xs={24} md={18} xl={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 24
            }}
          >
            <img
              style={{ height: 45, width: 150 }}
              src='./teaching-tax-flow-large.png'
              alt=''
            />
          </div>
          <Tabs defaultActiveKey='1'>
            <Tabs.TabPane tab='Calculators' key='1'>
              <Calculators />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Data' key='2'>
              <Data />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </Card>
  )
}

export default Home
