const numberFormatter = (value: number | undefined) =>
  `${Number(value || 0).toLocaleString(undefined, {
    maximumFractionDigits: 2
  })}`

const dollarFormatter = (value: number | undefined) =>
  `$ ${Number(value || 0).toLocaleString(undefined, {
    maximumFractionDigits: 2
  })}`

const percentageFormatter = (value: number | undefined) =>
  `${Number(value || 0).toLocaleString(undefined, {
    maximumFractionDigits: 2
  })} %`

const parser = (value: any) =>
  (value || 0).toString().replace(/\$\s?|(,*)/g, '')

export { dollarFormatter, numberFormatter, percentageFormatter, parser }
